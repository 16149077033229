import React from 'react';

const LeaveRequests = ({ leaveRequests, handleLeaveRequest }) => {
  return (
    <div className="p-4 rounded-lg shadow-md bg-white dark:bg-gray-800">
      <h2 className="text-xl font-semibold mb-4">Leave Requests</h2>
      <table className="table-auto w-full border-collapse border border-gray-200 dark:border-gray-700">
        <thead>
          <tr className="bg-gray-100 dark:bg-gray-700">
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">#</th>
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Employee Name</th>
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Leave Type</th>
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Start Date</th>
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">End Date</th>
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Status</th>
            <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {leaveRequests.map((request, index) => (
            <tr key={request.id} className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-800' : 'bg-white dark:bg-gray-900'}>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">{index + 1}</td>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">{request.employeeName}</td>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">{request.leaveType}</td>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">{request.startDate}</td>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">{request.endDate}</td>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">{request.status}</td>
              <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                <button
                  onClick={() => handleLeaveRequest(request.id, 'approved')}
                  className="py-1 px-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                >
                  Approve
                </button>
                <button
                  onClick={() => handleLeaveRequest(request.id, 'rejected')}
                  className="py-1 px-3 ml-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveRequests;
