import React, { useState } from 'react';
import EmployeeMonitoring from '../EmployeeMonitoring';

const EmployeeList = ({ employees }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const viewEmployeeMonitoring = (employeeId, userId) => {
    console.log("user id" +userId);
    setSelectedEmployee(employeeId);
    setSelectedUserId(userId);
  };

  return (
    <div>
      {selectedEmployee ? (
        <EmployeeMonitoring
          employeeId={selectedEmployee}
          userId = {selectedUserId}
          onBack={() => setSelectedEmployee(null)}
        />
      ) : (
        <div className="p-4 rounded-lg shadow-md bg-white dark:bg-gray-800">
          <h2 className="text-xl font-semibold mb-4">Employee List</h2>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-200 dark:border-gray-700">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-700">
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">#</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Actions</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Checked In</th>

                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Employee ID</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Name</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Email</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Username</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Role</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Status</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">DOB</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Designation</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Gender</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Address</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Marital Status</th>
                  <th className="border border-gray-300 dark:border-gray-600 px-4 py-2">Mobile Number</th>

                </tr>
              </thead>
              <tbody>
                {employees.map((employee, index) => (
                  <tr
                    key={employee.id}
                    className={
                      index % 2 === 0
                        ? 'bg-gray-50 dark:bg-gray-800'
                        : 'bg-white dark:bg-gray-900'
                    }
                  >
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {index + 1}
                    </td>

                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      <button
                        onClick={() => viewEmployeeMonitoring(employee.employeeId, employee.id)}
                        className="py-1 px-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                      >
                        Monitor
                      </button>
                    </td>

                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.checkedIn ? 'Yes' : 'No'}
                    </td>

                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.employeeId}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {`${employee.firstName} ${employee.middleName} ${employee.lastName}`}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.email}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.username}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.role}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.status}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.dob}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.designation}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.gender}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.address}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.maritalStatus}
                    </td>
                    <td className="border border-gray-300 dark:border-gray-600 px-4 py-2">
                      {employee.mobileNumber}
                    </td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeList;
