import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../../services/AxiosInstance'
import { LogOut, Moon, Sun, Bell, Menu } from 'lucide-react'
import { AnimatePresence } from 'framer-motion'
import Sidebar from './Sidebar'
import NotificationPanel from './NotificationPanel'
import Toast from './Toast'
import DashboardContent from './DashboardContent'

import ProfileContent from './ProfileContent'
import '../../../App.css';

export default function EmployeeDashboard() {
  const navigate = useNavigate()
  const [darkMode, setDarkMode] = useState(false)
  const [profileInfo, setProfileInfo] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    position: 'Software Developer',
    profilePicture: '/placeholder.svg?height=100&width=100'
  })
  const [notifications, setNotifications] = useState({ unread: [], read: [] })
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [activeSection, setActiveSection] = useState('dashboard')
  const [toast, setToast] = useState(null)
  const [isExpanded, setIsExpanded] = useState(true)
  const [employeeId, setEmployeeId] = useState(null)

  const showToast = useCallback((message, type) => {
    setToast({ message, type })
    setTimeout(() => setToast(null), 3000)
  }, [])

  const fetchProfileInfo = useCallback(async () => {
    const employeeId = localStorage.getItem('employeeId');
    if (!employeeId) {
      console.error('Employee ID not found in local storage')
      showToast('Failed to fetch profile info', 'error')
      return
    }

    try {
      const response = await axiosInstance.get(`/backend/auth/user/${employeeId}`)
      // console.log(response.data)
      setProfileInfo(response.data)
    } catch (error) {
      console.error('Failed to fetch profile info:', error)
      showToast('Failed to fetch profile info', 'error')
    }
  }, [showToast])

  const fetchAllNotifications = useCallback(async () => {
    try {
      const unreadResponse = await axiosInstance.get('/backend/notifications/unread')
      const readResponse = await axiosInstance.get('/backend/notifications/read')
      setNotifications({
        unread: unreadResponse.data,
        read: readResponse.data
      })
    } catch (error) {
      console.error('Failed to fetch notifications:', error)
      showToast('Failed to fetch notifications', 'error')
    }
  }, [showToast])

  useEffect(() => {
    const token = localStorage.getItem('token')
    const employeeId = localStorage.getItem('employeeId')
    setEmployeeId(employeeId)
    if (!token) {
      navigate('/adminlogin')
    }

    if (employeeId) {
      fetchProfileInfo()
      fetchAllNotifications()
    }
  }, [navigate, fetchProfileInfo, fetchAllNotifications])

  const markNotificationAsRead = useCallback(async (notificationId) => {
    try {
      await axiosInstance.post(`/backend/notifications/${notificationId}/read`)
      fetchAllNotifications()
    } catch (error) {
      console.error('Failed to mark notification as read:', error)
      showToast('Failed to mark notification as read', 'error')
    }
  }, [fetchAllNotifications, showToast])

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    navigate('/adminlogin')
  }, [navigate])


  useEffect(() => {
    const isDarkMode =
      localStorage.getItem('darkMode') === 'true' ||
      (!('darkMode' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    setDarkMode(isDarkMode)

    if (isDarkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [])

  const toggleDarkMode = useCallback(() => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
    localStorage.setItem('darkMode', newDarkMode.toString())

    if (newDarkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <div className="flex">
      <Sidebar
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      <div
        className={`min-h-screen w-full transition-all duration-200 ${isExpanded ? 'ml-48 ' : 'ml-16 '
          } ${darkMode ? 'dark' : ''}`}
      >
        <header className="bg-sky-900 text-white border-b">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex justify-between items-center">
            <div className="flex items-center">
              <button
                className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background hover:bg-accent hover:text-accent-foreground h-10 w-10 md:hidden"
                onClick={() => setIsSidebarOpen(true)}
              >
                <Menu className="h-5 w-5" />
                <span className="sr-only">Open sidebar</span>
              </button>
              <h1 className="text-2xl font-bold ml-2 md:ml-0">Employee Dashboard</h1>
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background hover:bg-accent hover:text-accent-foreground h-10 w-10"
                onClick={toggleDarkMode}
                aria-label="Toggle dark mode"
              >
                {darkMode ? <Sun className="h-5 w-5" color='yellow' /> : <Moon className="h-5 w-5 " color='white' />}
              </button>
              <button
                className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background hover:bg-accent hover:text-accent-foreground h-10 w-10"
                onClick={() => setIsNotificationPanelOpen(true)}
              >
                <Bell className="h-5 w-5" />
                <span className="sr-only">Open notifications</span>
              </button>
              <button
                className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-destructive text-destructive-foreground hover:bg-destructive/90 h-10 py-2 px-4"
                onClick={handleLogout}
              >
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </button>
            </div>
          </div>
        </header>

        <main className="flex-1 p-6">
          <div className="max-w-4xl mx-auto">
            <AnimatePresence mode="wait">
              {activeSection === 'dashboard' && (
                <DashboardContent
                  employeeId={employeeId}
                />
              )}
              {activeSection === 'profile' && (
                <ProfileContent profileInfo={profileInfo} />
              )}
            </AnimatePresence>
          </div>
        </main>
      </div>

      <NotificationPanel
        isOpen={isNotificationPanelOpen}
        setIsOpen={setIsNotificationPanelOpen}
        notifications={notifications}
        markNotificationAsRead={markNotificationAsRead}
      />

      <Toast toast={toast} setToast={setToast} />
    </div>
  )
}