import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

const ApplyNow = () => {
    const { title } = useParams();
    const [decodedTitle, setDecodedTitle] = useState('');
    const [formLoading, setFormLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        experience: '',
        skills: '',
        availability: '',
        agree: false
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (title) {
            const decoded = decodeURIComponent(title);
            setDecodedTitle(decoded);
            // Redirect if category is not one of the allowed values
            if (!["java", "uiux", "dm", "native"].includes(decoded)) {
                navigate("/");
            }

        }
    }, [title, navigate]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormLoading(true)
        const dataToSubmit = { ...formData, jobTitle: decodedTitle };

        try {
            const response = await axiosInstance.post("/backend/careerapi", dataToSubmit);
            if (response.status === 201) {
                // console.log(response.data);

                setSuccessMessage(`Successfully applied for ${decodedTitle}!`);
                setTimeout(() => {
                    localStorage.setItem('testId', response.data.id);
                    localStorage.setItem('jobTitle', response.data.jobTitle);
                    navigate('/start-test', { state: { title: response.data.jobTitle, id: response.data.id, } });
                }, 1000);
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setErrorMessage(err.response.data); // Display backend error message
            } else {
                setErrorMessage('Failed to submit application. Please try again later.');
            }
        } finally {
            setFormLoading(false)
        }

        // Reset the form fields after submission
        setFormData({
            name: '',
            email: '',
            mobile: '',
            experience: '',
            skills: '',
            availability: '',
            agree: false
        });
    };

    return (
        <div className="max-w-2xl mx-auto p-6 mt-2 bg-white shadow-lg rounded-lg border border-gray-200">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Apply for {decodedTitle || 'Position'}</h1>
            <p className="text-md text-gray-700 mb-4">
                Thank you for your interest in the {decodedTitle || 'Position'} position. Please fill out the form below to apply.
            </p>
            {errorMessage && (
                <div className="mb-4 text-red-600">
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className="mb-4 text-green-600">
                    {successMessage}
                </div>
            )}
            <form className="space-y-4" onSubmit={handleSubmit}>
                <input
                    type="hidden"
                    name="jobTitle"
                    value={decodedTitle}
                />
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-blue-500"
                    placeholder="Name"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-blue-500"
                    placeholder="Email"
                    required
                />
                <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-blue-500"
                    placeholder="Mobile"
                    required
                />
                <div className="flex space-x-4">
                    <select
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange}
                        className="flex-1 border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-blue-500"
                        required
                    >
                        <option value="">Experience Level</option>
                        <option value="beginner">Beginner</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="expert">Expert</option>
                    </select>
                    <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="availability">
                            Available to Join
                        </label>
                        <input
                            type="date"
                            name="availability"
                            id="availability"
                            value={formData.availability}
                            onChange={handleChange}
                            className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-blue-500"
                            placeholder="dd-mm-yyyy"
                            required
                        />
                    </div>
                </div>
                <textarea
                    name="skills"
                    value={formData.skills}
                    onChange={handleChange}
                    rows="4"
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-blue-500"
                    placeholder="Skills"
                    required
                />
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        name="agree"
                        id="agree"
                        checked={formData.agree}
                        onChange={handleChange}
                        className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        required
                    />
                    <label className="ml-2 text-sm text-gray-600 cursor-pointer" htmlFor='agree'>
                        I agree to the <Link href="#" className="text-blue-500 hover:underline">terms and conditions</Link>.
                    </label>
                </div>
                <div className="flex justify-center">
                    <button
                        type="submit"
                        disabled={formLoading}
                        className={`bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${formLoading ? 'cursor-not-allowed':''} `}
                    >
                        {formLoading ? "Loading" : "Submit Application"}

                    </button>
                </div>
            </form>
        </div>
    );
};

export default ApplyNow;
