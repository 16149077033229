import Header from '../components/Header'
import Services from '../components/Services'
import Footer from '../components/Footer'

import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export default function ServicesPage() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <main>
        <Services />

        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="text-center bg-green-900 text-white py-16 px-4 my-10 mx-6 rounded-3xl shadow-2xl overflow-hidden relative"
        >
          <div className="absolute top-0 right-0 w-64 h-64 bg-blue-800 rounded-full -mr-32 -mt-32 opacity-50"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-800 rounded-full -ml-32 -mb-32 opacity-50"></div>
          <h2 className="text-4xl font-bold mb-6 relative z-10">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto relative z-10">
            Let's collaborate to bring your vision to life and drive your business forward with innovative IT solutions.
          </p>
          <Link
            to="/contactus"
            className="inline-block bg-white text-blue-900 font-bold py-4 px-8 rounded-full text-xl transition duration-300 hover:bg-blue-100 hover:shadow-lg relative z-10"
          >
            Get Started Today
          </Link>
        </motion.section>

      </main>
      <Footer />
    </div>
  )
}