import React from 'react';
import { Truck, Users, GraduationCap, Calendar, UserCircle, DollarSign, ShieldCheck, MessageSquare, PenToolIcon as Tool, ArrowRight, CheckCircle } from 'lucide-react';

const TransportationERP = () => {
  const features = [
    {
      icon: Truck,
      title: "Fleet Management",
      description: "Comprehensive real-time tracking and analytics for your entire fleet.",
      details: [
        "GPS tracking and real-time location updates",
        "Fuel consumption monitoring and optimization",
        "Vehicle maintenance scheduling and alerts",
        "Driver performance tracking and scoring",
        "Route history and playback features"
      ]
    },
    {
      icon: Users,
      title: "Employee Management",
      description: "Streamline HR processes and boost productivity across your organization.",
      details: [
        "Employee onboarding and offboarding automation",
        "Time and attendance tracking",
        "Performance management and reviews",
        "Training and certification management",
        "Payroll integration and reporting"
      ]
    },
    {
      icon: GraduationCap,
      title: "Student Management",
      description: "Efficiently manage student transportation needs and ensure safety.",
      details: [
        "Student information database with transportation requirements",
        "Bus route assignment and optimization",
        "Attendance tracking for pick-ups and drop-offs",
        "Special needs accommodation management",
        "Parent communication portal for updates and changes"
      ]
    },
    {
      icon: Calendar,
      title: "Scheduling & Dispatch",
      description: "AI-powered route optimization and efficient scheduling for your fleet.",
      details: [
        "Intelligent route planning and optimization",
        "Real-time dispatch management",
        "Dynamic scheduling with drag-and-drop interface",
        "Automated driver assignment based on availability and qualifications",
        "Integration with traffic and weather data for accurate ETAs"
      ]
    },
    {
      icon: UserCircle,
      title: "Parent & Student Portal",
      description: "Keep everyone informed with real-time updates and easy communication.",
      details: [
        "Real-time bus tracking for parents and students",
        "Push notifications for delays or changes",
        "Secure messaging system between parents and transportation staff",
        "Online forms for transportation requests and changes",
        "Student ridership history and reports"
      ]
    },
    {
      icon: DollarSign,
      title: "Finance & Billing",
      description: "Integrated accounting and financial management for transportation operations.",
      details: [
        "Automated billing and invoicing for services",
        "Expense tracking and management",
        "Budget planning and forecasting tools",
        "Financial reporting and analytics dashboard",
        "Integration with popular accounting software"
      ]
    },
    {
      icon: ShieldCheck,
      title: "Compliance & Safety",
      description: "Ensure adherence to regulations and maintain high safety standards.",
      details: [
        "Automated compliance checks and alerts",
        "Driver qualification and certification tracking",
        "Vehicle inspection and maintenance logs",
        "Incident reporting and management system",
        "Safety performance analytics and reporting"
      ]
    },
    {
      icon: MessageSquare,
      title: "Communication & Reporting",
      description: "Seamless communication across your organization and comprehensive reporting.",
      details: [
        "Internal messaging and notification system",
        "Customizable report generation",
        "Real-time dashboards for key performance indicators",
        "Automated report scheduling and distribution",
        "Data export capabilities for further analysis"
      ]
    },
    {
      icon: Tool,
      title: "Maintenance & Support",
      description: "Proactive maintenance scheduling and responsive support for your fleet.",
      details: [
        "Preventive maintenance scheduling",
        "Repair and service history tracking",
        "Inventory management for parts and supplies",
        "Integration with preferred service providers",
        "24/7 support ticketing system"
      ]
    },
  ];

  const benefits = [
    "Seamless integration of all transportation business processes",
    "AI-powered route optimization for maximum efficiency and fuel savings",
    "Real-time tracking and analytics for informed decision-making",
    "Enhanced safety measures and compliance management",
    "Improved customer satisfaction through timely communication",
    "Scalable solution that grows with your business needs",
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <h1 className="text-4xl md:text-5xl font-bold mb-4 leading-tight">Revolutionize Your Transportation Business</h1>
              <p className="text-xl mb-8 text-blue-100">Harness the power of AI-driven ERP solutions to optimize your operations and drive growth.</p>
              <button className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full mr-4 hover:bg-blue-100 transition duration-300 shadow-lg">
                Get Your Free Demo
              </button>
            </div>
            <div className="md:w-1/2">
              <img 
                src="https://images.unsplash.com/photo-1519003722824-194d4455a60c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" 
                alt="Transportation ERP Dashboard" 
                className="rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-16">
        {/* Features Section */}
        <section className="mb-20">
          <h2 className="text-3xl font-semibold mb-12 text-center text-gray-800">Comprehensive ERP Modules</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300 border border-gray-200">
                <feature.icon className="h-12 w-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
                <p className="text-gray-600 mb-4">{feature.description}</p>
                <ul className="space-y-2">
                  {feature.details.map((detail, idx) => (
                    <li key={idx} className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{detail}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Benefits Section */}
        <section className="mb-20">
          <div className="bg-gradient-to-r from-blue-500 to-blue-700 rounded-lg shadow-xl p-8 text-white">
            <h2 className="text-3xl font-semibold mb-6">Why Choose Our Transportation ERP?</h2>
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {benefits.map((benefit, index) => (
                <li key={index} className="flex items-center">
                  <ArrowRight className="h-6 w-6 mr-3 text-blue-200 flex-shrink-0" />
                  <span className="text-lg">{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-lg p-10 text-center shadow-2xl">
          <h2 className="text-3xl font-semibold mb-4">Experience the Power of Our Transportation ERP</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Transform your transportation business today. Sign up for a free demo and discover how our AI-driven solutions can optimize your operations.
          </p>
          <button className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full hover:bg-blue-100 transition duration-300 shadow-lg text-lg">
            Get Your Free Demo
          </button>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About Us</h3>
              <p className="text-gray-400">We are dedicated to revolutionizing the transportation industry with cutting-edge ERP solutions.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="/" className="text-gray-400 hover:text-white transition duration-300">Home</a></li>
                <li><a href="/" className="text-gray-400 hover:text-white transition duration-300">Features</a></li>
                <li><a href="/" className="text-gray-400 hover:text-white transition duration-300">Pricing</a></li>
                <li><a href="/" className="text-gray-400 hover:text-white transition duration-300">Contact</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <p className="text-gray-400">Email: info@ashdipitsolutions.in</p>
              <p className="text-gray-400">Phone: 9309234245</p>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p className="text-gray-400">&copy; 2024 Transportation ERP. All rights reserved.</p>
            <div className="mt-4">
              <a href="/privacy" className="text-sm text-gray-400 hover:text-white mr-4 transition duration-300">Privacy Policy</a>
              <a href="/terms" className="text-sm text-gray-400 hover:text-white transition duration-300">Terms of Service</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TransportationERP;