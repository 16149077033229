import React, { useState, useEffect ,useCallback} from 'react';
import { motion } from 'framer-motion';
import { List, ArrowLeft } from 'lucide-react';
import RecordedSessions from './RecordedSessions';
import axiosInstance from '../../../services/AxiosInstance';

const EmployeeMonitoring = ({ employeeId, userId, onBack }) => {
  const [showRecordedSessions, setShowRecordedSessions] = useState(false);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleRecordedSessions = () => {
    setShowRecordedSessions(!showRecordedSessions);
  };

  const fetchAttendance = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/backend/employee/attendencerecords/${userId}`);
      console.log(response.data);
      setAttendanceRecords(response.data); // Assuming API returns an array of records
    } catch (error) {
      console.error('Error fetching attendance:', error);
    } finally {
      setLoading(false);
    }
  }, [userId]); // Add userId as a dependency

  useEffect(() => {
    fetchAttendance();
  }, [fetchAttendance]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <button
        onClick={onBack}
        className="mb-4 flex items-center text-blue-600 hover:text-blue-800"
      >
        <ArrowLeft className="mr-2" /> Back to List
      </button>

      <div className="rounded-lg border bg-white text-card-foreground shadow-sm p-6">
        <h3 className="text-xl font-semibold mb-4">Attendance Records</h3>
        {loading ? (
          <p>Loading attendance...</p>
        ) : attendanceRecords.length > 0 ? (
          <table className="table-auto w-full border-collapse border border-gray-200 bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2 text-left">Check-in Time</th>
                <th className="border px-4 py-2 text-left">Check-out Time</th>
                <th className="border px-4 py-2 text-left">Total Work Time (hrs)</th>
                <th className="border px-4 py-2 text-left">Total Break Time</th>
                <th className="border px-4 py-2 text-left">OnBreak</th>
              </tr>
            </thead>
            <tbody>
              {attendanceRecords.map((record, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{new Date(record.checkInTime).toLocaleString()}</td>
                  <td className="border px-4 py-2">
                    {record.checkOutTime ? new Date(record.checkOutTime).toLocaleString() : 'N/A'}
                  </td>
                  <td className="border px-4 py-2">{record.totalWorkTime.toFixed(2)}</td>
                  <td className="border px-4 py-2">{record.totalBreakTime.toFixed(2)}</td>
                  <td className="border px-4 py-2">{record.onBreak ? 'yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No attendance records found.</p>
        )}
      </div>

      <div className="col-span-full">
        <button
          onClick={toggleRecordedSessions}
          className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 px-4 py-2"
        >
          <List className="mr-2 h-4 w-4" />
          {showRecordedSessions ? 'Hide' : 'Show'} Recorded Sessions
        </button>
      </div>

      {showRecordedSessions && (
        <div>
          <RecordedSessions employeeId={employeeId} />
        </div>
      )}
    </motion.div>
  );
};

export default EmployeeMonitoring;
