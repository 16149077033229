import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDarkMode } from '../DarkModeContext'; // Import the custom hook

const Navbar = () => {
    const { darkMode } = useDarkMode(); // Access darkMode state

    return (
        <nav className={`shadow-md transition-colors border-t  duration-200 ${darkMode ? 'bg-gray-900' : 'bg-white'} relative top-0`}>
            <div className="container mx-auto flex flex-wrap justify-between items-center p-3">
                {/* Left Section: Contact Info */}
                <div className="flex flex-col md:flex-row md:space-x-6 items-start md:items-center text-sm text-gray-700 dark:text-gray-100">
                    <div className="flex items-center mb-2 md:mb-0">
                        <i className="fa-solid fa-phone mx-1"></i>
                        <span>9309234245</span>
                    </div>
                    <div className="flex items-center mb-2 md:mb-0">
                        <i className="fa-solid fa-location-dot mx-1"></i>
                        <span>PIMPRI, PUNE</span>
                    </div>
                    <div className="flex items-center">
                        <i className="fa-solid fa-envelope mx-1"></i>
                        <span>info@ashdipitsolutions.in</span>
                    </div>
                </div>

                {/* Right Section: NavLinks */}
                <div className="flex flex-wrap items-center space-x-4 md:space-x-8">
                    <NavLink to="/" className={`text-${darkMode ? 'gray-200' : 'black'} hover:text-primary font-bold`}>
                        Home
                    </NavLink>
                    <NavLink to="/career" className={`text-${darkMode ? 'gray-200' : 'black'} hover:text-primary font-bold`}>
                        Career
                    </NavLink>
                    <NavLink to="/contactus" className={`text-${darkMode ? 'gray-200' : 'black'} hover:text-primary font-bold`}>
                        Contact Us
                    </NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
