import React, { useState, useEffect, useRef } from 'react'
import axiosInstance from '../../../services/AxiosInstance'
import { format } from 'date-fns'

const RecordedSessions = ({ employeeId }) => {
  const [recordings, setRecordings] = useState({})
  const [selectedRecording, setSelectedRecording] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const videoRef = useRef(null)

  useEffect(() => {
    const fetchRecordings = async () => {
      try {
        const response = await axiosInstance.get(`/backend/recording/screen/${employeeId}`)
        setRecordings(response.data)
      } catch (error) {
        console.error('Failed to fetch recordings:', error)
      }
    }
    fetchRecordings()
  }, [employeeId])

  const handleSelectRecording = async (fileName) => {
    try {
      const response = await axiosInstance.get(`/backend/recording/snapshot/${fileName}`, {
        responseType: 'blob'
      })
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const url = URL.createObjectURL(blob)
      setSelectedRecording(url)
      
      if (videoRef.current) {
        videoRef.current.src = url
        videoRef.current.play().catch(e => console.error("Error auto-playing video:", e))
      }
    } catch (error) {
      console.error('Failed to fetch recording:', error)
    }
  }

  const handleDeleteRecordings = async (date) => {
    try {
        const recordingType = 'screen';
      await axiosInstance.delete(`/backend/recording/delete/${employeeId}/${recordingType}/${date}`)
      const updatedRecordings = { ...recordings }
      delete updatedRecordings[date]
      setRecordings(updatedRecordings)
      if (selectedDate === date) {
        setSelectedDate(null)
        setSelectedRecording(null)
      }
    } catch (error) {
      console.error('Failed to delete recordings:', error)
    }
  }

  useEffect(() => {
    return () => {
      if (selectedRecording) {
        URL.revokeObjectURL(selectedRecording)
      }
    }
  }, [selectedRecording])

  return (
    <div className="col-span-full mt-6">
      <h3 className="text-xl font-semibold mb-4">Recorded Sessions</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          {Object.keys(recordings).length === 0 ? (
            <p>No recorded sessions found.</p>
          ) : (
            <ul className="space-y-2 max-h-96 overflow-y-auto">
              {Object.entries(recordings).sort(([a], [b]) => new Date(b) - new Date(a)).map(([date, files]) => (
                <li key={date} className="mb-4">
                  <div className="flex justify-between items-center bg-gray-200 p-2 rounded">
                    <span className="font-semibold">{format(new Date(date), 'MMMM d, yyyy')}</span>
                    <button
                      onClick={() => handleDeleteRecordings(date)}
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded text-sm"
                    >
                      Delete All
                    </button>
                  </div>
                  <ul className="mt-2 pl-4">
                    {files.map((file, index) => (
                      <li 
                        key={index}
                        className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                        onClick={() => {
                          handleSelectRecording(file)
                          setSelectedDate(date)
                        }}
                      >
                        {file}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          {selectedRecording && (
            <div>
              <h4 className="text-lg font-semibold mb-2">
                Selected Recording - {selectedDate && format(new Date(selectedDate), 'MMMM d, yyyy')}
              </h4>
              <video 
                ref={videoRef}
                src={selectedRecording} 
                controls
                autoPlay
                className="w-full h-auto"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RecordedSessions

