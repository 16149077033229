import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Lightbulb, Rocket, Handshake, CheckCircle} from 'lucide-react'

const services = [
  {
    title: 'Custom Software Development',
    description: 'Tailored solutions designed to meet your specific business needs, from web applications to mobile apps and enterprise software.',
    icon: '💻'
  },
  {
    title: 'Cloud Solutions',
    description: 'Seamless cloud migration, optimization, and management services to enhance scalability, security, and performance.',
    icon: '☁️'
  },
  {
    title: 'Cybersecurity',
    description: 'Comprehensive security solutions to protect your digital assets, ensure compliance, and mitigate risks.',
    icon: '🛡️'
  },
  {
    title: 'Digital Transformation',
    description: 'Strategic consulting and implementation services to guide your organizations digital evolution and stay competitive.',
    icon: '🚀'
  },
  {
    title: 'UX/UI Design',
    description: 'Creating intuitive and visually appealing user interfaces that enhance user experience and drive engagement.',
    icon: '🎨'
  },
  {
    title: 'Web Design & Development',
    description: 'Building responsive, fast, and SEO-friendly websites that represent your brand and drive conversions.',
    icon: '🌐'
  },
  {
    title: 'Web App Development',
    description: 'Developing robust and scalable web applications that streamline your business processes and improve efficiency.',
    icon: '⚙️'
  },
  {
    title: 'Mobile App Development',
    description: 'Crafting native and cross-platform mobile apps that provide seamless experiences across all devices.',
    icon: '📱'
  }
]

const reasons = [
  { title: 'Expertise', description: 'Our team of skilled professionals brings years of experience across various industries and technologies.', icon: <Lightbulb className="w-8 h-8" /> },
  { title: 'Innovation', description: 'We stay at the forefront of technology trends to deliver cutting-edge solutions that give you a competitive edge.', icon: <Rocket className="w-8 h-8" /> },
  { title: 'Partnership', description: 'We view our clients as long-term partners, committed to your success beyond project completion.', icon: <Handshake className="w-8 h-8" /> },
  { title: 'Quality', description: 'Our rigorous quality assurance processes ensure reliable, scalable, and high-performance solutions.', icon: <CheckCircle className="w-8 h-8" /> }
]

const phases = [
  { step: 1, title: 'Discovery', description: 'We start by understanding your business goals, challenges, and requirements.' },
  { step: 2, title: 'Strategy', description: 'Our experts develop a tailored strategy and roadmap to address your specific needs.' },
  { step: 3, title: 'Implementation', description: 'We bring your solution to life using agile methodologies and best practices.' },
  { step: 4, title: 'Support & Optimization', description: 'We provide ongoing support and continuously optimize your solution for peak performance.' }
]

const team = [
  { name: 'Ashok Sonawane', role: 'Founder & CEO, Digital Marketing Manager', image: '/placeholder.svg?height=300&width=300' },
  { name: 'Ankita Sonawane', role: 'Director, Team Manager', image: '/placeholder.svg?height=300&width=300' },
  { name: 'Sandip Moore', role: 'Director, CTO', image: '/placeholder.svg?height=300&width=300' },
  { name: 'Rushikesh Warade', role: 'Full Stack Developer', image: '/placeholder.svg?height=300&width=300' },
  { name: 'Abhay Deshmukh', role: 'Full Stack Developer', image: '/placeholder.svg?height=300&width=300' },
  { name: 'Vishal Sabale', role: 'Full Stack Developer', image: '/placeholder.svg?height=300&width=300' }
]

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-white font-serif py-20">
          <h1 className="text-5xl font-extrabold text-center  text-black mb-4 ">About Us</h1>
          <div className="flex justify-center items-center space-x-1">
            {[...Array(5)].map((_, i) => (
              <svg key={i} className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>

      <main className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-24 bg-white rounded-3xl shadow-2xl p-12 overflow-hidden relative"
        >

            
          <div className="absolute top-0 right-0 w-64 h-64 bg-blue-100 rounded-full -mr-32 -mt-32 opacity-50"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-100 rounded-full -ml-32 -mb-32 opacity-50"></div>
          <h2 className="text-4xl font-bold text-black mb-8 relative z-10">Our Mission</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center relative">
            <div>
              <p className="text-xl text-gray-700 mb-6 leading-relaxed text-justify">
                At Ashdip IT Solutions, we're on a mission to empower businesses through innovative technology solutions. We believe that the right IT strategy can transform organizations, drive growth, and create lasting value.
              </p>
              <p className="text-xl text-gray-700 leading-relaxed text-justify">
                Our team of expert developers, designers, and consultants work tirelessly to deliver cutting-edge solutions that address the unique challenges of each client. From custom software development to comprehensive digital transformation initiatives, we're here to guide you every step of the way.
              </p>
            </div>
            <div className="relative h-80 md:h-full">
              <img
                src=".\images\team-collboration-img.jpg?height=400&width=600"
                alt="Ashdip IT Solutions team collaborating"
                className="rounded-3xl shadow-lg object-cover w-full h-full"
              />
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mb-24"
        >
          <h2 className="text-4xl font-bold text-black mb-12 text-center">Our Expertise</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <motion.div 
                key={index}
                whileHover={{ scale: 1.05, boxShadow: '0 10px 30px -15px rgba(0, 0, 0, 0.3)' }}
                className="bg-white rounded-3xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 border-t-4 border-blue-500"
              >
                <div className="text-5xl mb-6">{service.icon}</div>
                <h3 className="text-2xl font-semibold text-black mb-4">{service.title}</h3>
                <p className="text-gray-700 leading-relaxed text-justify">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mb-24 bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-3xl shadow-2xl p-12 overflow-hidden relative"
        >
          <div className="absolute top-0 right-0 w-64 h-64 bg-white rounded-full -mr-32 -mt-32 opacity-10"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-white rounded-full -ml-32 -mb-32 opacity-10"></div>
          <h2 className="text-4xl font-bold mb-12 text-center relative z-10">Why Choose Ashdip IT Solutions?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 relative z-10">
            {reasons.map((reason, index) => (
              <motion.div 
                key={index}
                whileHover={{ scale: 1.05 }}
                className="bg-white bg-opacity-10 rounded-2xl p-8 backdrop-filter backdrop-blur-lg"
              >
                <div className="text-4xl mb-4 text-blue-300">{reason.icon}</div>
                <h3 className="text-2xl font-semibold mb-4">{reason.title}</h3>
                <p className="text-blue-100 leading-relaxed text-justify">{reason.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.6 }}
      className="mb-24"
    >
      <h2 className="text-4xl font-bold text-black mb-12 text-center">Our Proven Process</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {phases.map((phase, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.05 }}
            className="bg-white rounded-3xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 border-l-4 border-black hover:bg-blue-50 group"
          >
            <motion.div 
              className="text-6xl font-bold text-black mb-4"
              whileHover={{ rotate: 360 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <motion.span 
                className="bg-blue-100 text-black rounded-full w-12 h-12 flex items-center justify-center text-2xl font-bold mr-4 group-hover:bg-blue-200"
                whileHover={{ y: -5 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                {phase.step}
              </motion.span>
            </motion.div>
            <h3 className="text-2xl font-semibold text-black mb-4 group-hover:text-blue-600">{phase.title}</h3>
            <p className="text-gray-700 leading-relaxed text-justify group-hover:text-blue-800">{phase.description}</p>
          </motion.div>
        ))}
      </div>
    </motion.section>

        <motion.section 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        className="mb-24 bg-gradient-to-br from-blue-50 to-indigo-100 rounded-3xl shadow-2xl p-12 overflow-hidden relative"
      >
        <div className="absolute top-0 right-0 w-64 h-64 bg-blue-200 rounded-full -mr-32 -mt-32 opacity-50"></div>
        <div className="absolute bottom-0 left-0 w-64 h-64 bg-indigo-200 rounded-full -ml-32 -mb-32 opacity-50"></div>
        <h2 className="text-4xl font-bold text-black mb-12 text-center relative z-10">Meet Our Exceptional Team</h2>
        <div className="relative z-10 overflow-x-auto pb-4 -mx-4 px-4">
          <div className="flex space-x-8" style={{ width: `${team.length * 300}px` }}>
            {team.map((member, index) => (
              <motion.div 
                key={index}
                whileHover={{ y: -10, boxShadow: '0 10px 40px -15px rgba(0, 0, 0, 0.2)' }}
                className="bg-white rounded-2xl shadow-lg overflow-hidden flex-shrink-0 transition-all duration-300"
                style={{ width: '280px' }}
              >
                <div className="relative">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-72 object-cover object-center"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                  <div className="absolute bottom-4 left-4 right-4 text-white">
                    <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
                    <p className="text-sm text-blue-200">{member.role}</p>
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-gray-600 text-sm mb-4 text-justify">
                    Passionate about delivering innovative solutions and exceeding client expectations.
                  </p>
                  {/* <div className="flex space-x-4 justify-center">
                    <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target='_blank' rel="noreferrer" className="text-black hover:text-blue-600 transition-colors">
                      <Linkedin className="w-5 h-5" />
                    </a>
                    <a href="https://x.com/Ashdipit" target='_blank' rel="noreferrer" className="text-black hover:text-blue-600 transition-colors">
                      <X className="w-5 h-5" />
                    </a>
                    <a href="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg" target='_blank' rel="noreferrer" className="text-black hover:text-blue-600 transition-colors">
                      <Facebook className="w-5 h-5" />
                    </a>
                  </div> */}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <span className="text-black text-sm font-medium">Swipe to see more →</span>
        </div>
      </motion.section>

        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="text-center bg-green-900 text-white py-16 px-4 rounded-3xl shadow-2xl overflow-hidden relative"
        >
          <div className="absolute top-0 right-0 w-64 h-64 bg-blue-800 rounded-full -mr-32 -mt-32 opacity-50"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-800 rounded-full -ml-32 -mb-32 opacity-50"></div>
          <h2 className="text-4xl font-bold mb-6 relative z-10">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto relative z-10">
            Let's collaborate to bring your vision to life and drive your business forward with innovative IT solutions.
          </p>
          <Link
            to="/contactus"
            className="inline-block bg-white text-blue-900 font-bold py-4 px-8 rounded-full text-xl transition duration-300 hover:bg-blue-100 hover:shadow-lg relative z-10"
          >
            Get in Touch
          </Link>
        </motion.section>
      </main>

    </div>
  )
}

export default AboutPage

