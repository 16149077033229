import React, { useEffect, useState } from 'react';
import { Coffee, Utensils, MoreHorizontal } from 'lucide-react';

const BreaksContent = ({ currentBreak, handleBreakRequest, handleEndBreak, todayRecords }) => {
  // eslint-disable-next-line
  const [countdown, setCountdown] = useState(null);
  const [storedBreak, setStoredBreak] = useState(
    () => JSON.parse(localStorage.getItem('currentBreak')) || currentBreak
  );
  const [selectedBreakType, setSelectedBreakType] = useState(null);

  useEffect(() => {
    if (storedBreak) {
      const breakEnd =
        new Date(storedBreak.breakStartTime).getTime() + storedBreak.duration * 60 * 1000;

      // Set initial countdown
      const now = Date.now();
      const timeLeft = Math.max(breakEnd - now, 0);
      setCountdown(timeLeft > 0 ? Math.floor(timeLeft / 1000) : null);

      // Update countdown every second
      const interval = setInterval(() => {
        const now = Date.now();
        const updatedTimeLeft = Math.max(breakEnd - now, 0);
        setCountdown(updatedTimeLeft > 0 ? Math.floor(updatedTimeLeft / 1000) : null);

        if (updatedTimeLeft <= 0) {
          clearInterval(interval);
          localStorage.removeItem('currentBreak'); // Clear stored break when countdown ends
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [storedBreak]);

  useEffect(() => {
    // Sync storedBreak with prop changes
    if (currentBreak) {
      setStoredBreak(currentBreak);
    }
  }, [currentBreak]);

  const breakTypes = [
    { type: 'lunch', icon: Utensils, label: 'Lunch Break', duration: 45, maxTimes: 1 },
    { type: 'tea', icon: Coffee, label: 'Tea Break', duration: 10, maxTimes: 2 },
    { type: 'other', icon: MoreHorizontal, label: 'Other Break', duration: 15, maxTimes: Infinity },
  ];

  const isBreakTaken = (type) => {
    const taken = todayRecords.filter((record) => record.breakType === type);
    const breakConfig = breakTypes.find((breakType) => breakType.type === type);
    return taken.length >= (breakConfig?.maxTimes || 0);
  };

  // eslint-disable-next-line
  const isActiveBreak = (breakType) => {
    return todayRecords.some(
      (record) => record.breakType === breakType && !record.endTime
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-2">Break Management</h2>
      <p className="text-sm text-gray-600 mb-4">Manage your breaks throughout the day</p>

      {selectedBreakType ? (
        <button
          onClick={() => {
            const activeBreak = todayRecords.find(
              (record) => record.breakType === selectedBreakType && record.onBreak
            );
            if (activeBreak) {
              handleEndBreak(activeBreak.id);
              setSelectedBreakType(null); // Reset selected break type
            }
          }}
          className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-200"
        >
          End {breakTypes.find((breakType) => breakType.type === selectedBreakType)?.label || 'Selected Break'}
        </button>

      ) : (
        <div className="grid grid-cols-3 gap-4">
          {breakTypes.map(({ type, icon: Icon, label, duration }) => (
            <button
              key={type}
              className={`flex flex-col items-center justify-center h-24 space-y-2 border border-gray-200 rounded-lg transition duration-200 ${isBreakTaken(type) ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'hover:bg-gray-50'
                }`}
              onClick={() => {
                handleBreakRequest(type, duration);
                setStoredBreak({ breakType: type, breakStartTime: new Date(), duration });
                setSelectedBreakType(type); // Set selected break type
              }}
              disabled={isBreakTaken(type)}
            >
              <Icon className={`h-8 w-8 ${isBreakTaken(type) ? 'text-gray-400' : 'text-gray-600'}`} />
              <span>{label}</span>
              <span className="text-sm text-gray-500">{duration} min</span>
            </button>
          ))}
        </div>
      )}

      <p className="text-sm text-gray-600 mt-4">
        Remember to take regular breaks to stay productive and maintain your well-being.
      </p>
    </div>
  );
};

export default BreaksContent;
