import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom/dist';
import {isLoggedIn} from "../../../auth";

const ShowMcq = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("adminlogin");
        }

    }, [navigate])



    const [mcqs, setMcqs] = useState([]);
    const [jobTitle, setJobTitle] = useState('all');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedMcqId, setSelectedMcqId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchMcqs(jobTitle);
    }, [jobTitle]);

    const fetchMcqs = async (title) => {
        setLoading(true);
        setError(null);
        setSuccessMessage('');
        try {
            const response = await axiosInstance.get(`/backend/mcq/${title === 'all' ? 'question' : `job/${title}`}`);
            setMcqs(response.data);
        } catch (err) {
            setError('Failed to fetch MCQs.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/backend/mcq/question/delete/${selectedMcqId}`);
            setMcqs(mcqs.filter(mcq => mcq.id !== selectedMcqId));
            setShowModal(false);
            setSuccessMessage('MCQ deleted successfully.');
        } catch (err) {
            setError('Failed to delete MCQ.');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (id) => {
        console.log(`Update MCQ with ID: ${id}`);
        navigate('/admin/update-mcq', { state: { id } });
    };

    const handleJobTitleChange = (e) => {
        setJobTitle(e.target.value);
    };



    const openModal = (id) => {
        setSelectedMcqId(id);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedMcqId(null);
    };


    


    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">MCQ Table</h2>
            <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Filter by Job Title</label>
                <select
                    value={jobTitle}
                    onChange={handleJobTitleChange}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                >
                    <option value="all">All</option>
                    <option value="java">Java</option>
                    <option value="dm">Digital Marketing</option>
                    <option value="uiux">UI/UX</option>
                    <option value="native">React Native</option>
                </select>
            </div>

            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            {!loading && !error && mcqs.length > 0 && (
                <div className="overflow-auto rounded-lg " style={{ maxHeight: '500px' }}>
                    <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 border-b">#</th>
                                <th className="px-4 py-2 border-b">Question</th>
                                <th className="px-4 py-2 border-b">Option A</th>
                                <th className="px-4 py-2 border-b">Option B</th>
                                <th className="px-4 py-2 border-b">Option C</th>
                                <th className="px-4 py-2 border-b">Option D</th>
                                <th className="px-4 py-2 border-b">Correct Answer</th>
                                <th className="px-4 py-2 border-b">Job Title</th>
                                <th className="px-4 py-2 border-b">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mcqs.map((mcq, index) => (
                                <tr key={mcq.id}>
                                    <td className="px-4 py-2 border-b">{index + 1}</td>
                                    <td className="px-4 py-2 border-b"> <pre className=' w-96 whitespace-pre-wrap break-words' >{mcq.question}</pre> </td>
                                    <td className="px-4 py-2 border-b">{mcq.optionA}</td>
                                    <td className="px-4 py-2 border-b">{mcq.optionB}</td>
                                    <td className="px-4 py-2 border-b">{mcq.optionC}</td>
                                    <td className="px-4 py-2 border-b">{mcq.optionD}</td>
                                    <td className="px-4 py-2 border-b">{mcq.correctAnswer}</td>
                                    <td className="px-4 py-2 border-b">{mcq.jobTitle}</td>
                                    <td className="px-4 py-2 border-b">
                                        <div className="flex justify-center items-center">
                                            <button
                                                onClick={() => handleUpdate(mcq.id)}
                                                className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button
                                                onClick={() => openModal(mcq.id)}
                                                className="bg-red-500 text-white px-2 py-1 rounded"
                                            >
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {!loading && !error && mcqs.length === 0 && <p>No MCQs found.</p>}

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
                        <p>Are you sure you want to delete this MCQ?</p>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={closeModal}
                                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowMcq;
