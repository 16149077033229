import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../services/AxiosInstance';

const Notifications = () => {
  const [message, setMessage] = useState('');
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const unreadResponse = await axiosInstance.get('/api/notifications/unread');
      setUnreadNotifications(unreadResponse.data);

      const readResponse = await axiosInstance.get('/api/notifications/read');
      setReadNotifications(readResponse.data);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  const handleNotificationCreate = async () => {
    try {
      await axiosInstance.post('/backend/notifications/create', { message });
      setMessage('');
      alert('Notification created successfully!');
      fetchNotifications(); // Refresh notifications after creating a new one
    } catch (error) {
      console.error('Failed to create notification:', error);
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      await axiosInstance.post(`/backend/notifications/${notificationId}/read`);
      fetchNotifications(); // Refresh notifications after marking as read
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  return (
    <div className="p-4 rounded-lg shadow-md bg-white dark:bg-gray-800">
      <h2 className="text-xl font-semibold mb-4">Create Notification</h2>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows="4"
        className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md"
        placeholder="Write a notification message..."
      />
      <button
        onClick={handleNotificationCreate}
        className="mt-2 py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
      >
        Create Notification
      </button>

      <h2 className="text-xl font-semibold mt-8 mb-4">Unread Notifications</h2>
      <ul className="space-y-2">
        {unreadNotifications.map((notification) => (
          <li key={notification.id} className="flex justify-between items-center bg-blue-100 dark:bg-blue-900 p-2 rounded">
            <span>{notification.message}</span>
            <button
              onClick={() => handleMarkAsRead(notification.id)}
              className="ml-2 py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
            >
              Mark as Read
            </button>
          </li>
        ))}
      </ul>

      <h2 className="text-xl font-semibold mt-8 mb-4">Read Notifications</h2>
      <ul className="space-y-2">
        {readNotifications.map((notification) => (
          <li key={notification.id} className="bg-gray-100 dark:bg-gray-700 p-2 rounded">
            {notification.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notifications;

