import React from 'react'
import OurProducts from '../components/OurProducts'
import Header from '../components/Header'
import Footer from '../components/Footer'

const AboutPage = () => {
  return (
    <div className="">
        <Header />
      <OurProducts />
      <Footer />
    </div>
  )
}

export default AboutPage