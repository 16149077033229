import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Ashdiplogowhite.png';
import { useDarkMode } from '../DarkModeContext'; // Import the custom hook

const DetailNavbar = () => {
    const { darkMode, setDarkMode } = useDarkMode(); // Access darkMode state

    return (
        <nav className={`p-0 transition-colors duration-200 ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
            <div className="container mx-auto flex flex-wrap justify-between items-center">
                {/* Logo Section */}
                <div className="flex items-center">
                    <img src={logo} alt="logo" className={`w-44 ${darkMode ? '' : 'filter invert'}`} />
                </div>

                {/* Social Icons & Login Button */}
                <div className="flex flex-row items-center gap-4">

                    {/* Dark Mode Toggle Button */}
                    <div className="ml-auto">
                    <button
                        onClick={() => setDarkMode(!darkMode)}
                        className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-yellow-400' : 'bg-gray-200 text-gray-800'}`}
                        aria-label="Toggle dark mode"
                    >
                        {darkMode ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                            </svg>
                        )}
                    </button>
                    </div>
                    <Link to="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg" className={`text-${darkMode ? 'white' : 'gray-800'} hover:text-blue-500`}>
                        <i className="fa-brands fa-facebook"></i>
                    </Link>
                    <Link to="https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==" className={`text-${darkMode ? 'white' : 'gray-800'} hover:text-pink-500`}>
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to="https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className={`text-${darkMode ? 'white' : 'gray-800'} hover:text-blue-700`}>
                        <i className="fa-brands fa-linkedin"></i>
                    </Link>
                    <Link to="/adminlogin" className={`bg-blue-900 text-white py-2 px-4 me-2 rounded-full hover:bg-blue-600 ${darkMode ? 'text-gray-100' : 'text-white'}`}>
                        Login
                    </Link>
                </div>

            </div>
        </nav>
    );
};

export default DetailNavbar;
