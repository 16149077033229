import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

const UploadDocument = () => {
    const location = useLocation();
    const testId = location.state?.id;
    const user = location.state?.user;
    const navigate = useNavigate();

    useEffect(() => {
        if (testId == null) {
            navigate("/career");
        }
    }, [testId, navigate]);

    const [documents, setDocuments] = useState({
        resume: null,
        sscCertificate: null,
        hscDiplomaCertificate: null,
        degree: null,
        aadhar: null,
        internship: null,
        experience: null,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes

        if (file && file.size > maxSize) {
            alert('File size should not exceed 2 MB');
            e.target.value = ''; // Clear the file input
            setDocuments(prevDocuments => ({ ...prevDocuments, [field]: null }));
            return;
        }

        setDocuments(prevDocuments => ({ ...prevDocuments, [field]: file }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading

        const formData = new FormData();
        Object.keys(documents).forEach((key) => {
            if (documents[key]) {
                formData.append(key, documents[key]);
            }
        });

        try {
            await axiosInstance.post(`/backend/document/upload/${testId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage('Your application was submitted successfully.');
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error uploading documents:', error);
            setMessage('There was an error submitting your application. Please try again.');
            setIsModalOpen(true);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (message.includes('successfully')) {
            if (user === "admin") {
                navigate("/admin/dashboard");
            } else {
                navigate("/");  // Redirect only on success
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto mt-2 p-5 bg-white shadow-lg rounded-lg border border-gray-200">
            <h2 className="text-3xl font-bold text-gray-800 p-2 bg-green-500 rounded-lg mb-2 text-center">Upload Your Documents</h2>
            <p className='text-center text-gray-500 mb-4'>Now, you have completed the test! To proceed with your application, please upload your documents. These will help us better understand your qualifications and ensure a smooth review process.</p>
            <form onSubmit={handleSubmit} className="space-y-6">
                {[
                    { label: "Resume", field: "resume" },
                    { label: "10th Certificate", field: "sscCertificate" },
                    { label: "12th Certificate", field: "hscDiplomaCertificate", optional: true },
                    { label: "Highest Degree Certificate", field: "degree" },
                    { label: "Aadhaar Card", field: "aadhar" },
                    { label: "Internship Letter", field: "internship", optional: true },
                    { label: "Experience Letter", field: "experience", optional: true },
                ].map(({ label, field, optional }) => (
                    <div key={field} className="flex justify-between items-center">
                        <label className="text-lg font-medium text-gray-700">
                            {label} {optional && <span className="text-sm text-gray-500">(Optional)</span>}
                        </label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e, field)}
                            required={optional !== true}
                            className="w-1/2 text-gray-600 p-1 border border-gray-300 rounded-md cursor-pointer"
                        />
                    </div>
                ))}
                <div className="text-center">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
                    >
                        Submit
                    </button>
                </div>
            </form>

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="loader"> {/* Add your spinner/loading animation here */} 
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className=" animate-spin w-50 h-50"
                    >
                        <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        ></circle>
                        <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 shadow-lg max-w-md w-full">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">{message.includes('successfully') ? 'Submitted Successfully' : 'Submission Error'}</h3>
                        <p className="text-gray-600 mb-6">{message}</p>
                        <button
                            onClick={closeModal}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UploadDocument;
