import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import Swal from 'sweetalert2';
import {  ArrowUpRightIcon } from 'lucide-react';
import InquiryFormModal from './Projectinquirymodel'; // Ensure this is the correct path
import axiosInstance from '../services/AxiosInstance'; // Your Axios setup
import { Helmet } from 'react-helmet';

export default function ContactForm({ className = "" }) {
  const currentUrl = window.location.href;
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    contactname: "",
    contactphone: "",
    contactemail: "",
    contactmessage: "",
  });
  const [errors, setErrors] = useState({});

  const handleModalOpen = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.contactname) {
      newErrors.contactname = "--- Name is required";
    }
    if (!formData.contactphone) {
      newErrors.contactphone = "--- Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.contactphone)) {
      newErrors.contactphone = "--- Please enter a valid 10-digit mobile number.";
    }
    if (formData.contactemail && !/\S+@\S+\.\S+/.test(formData.contactemail)) {
      newErrors.contactemail = "--- Please enter a valid email address.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        await axiosInstance.post("/backend/savecontact", formData); // API call to your backend
        // SweetAlert success message
        Swal.fire({
          title: 'Success!',
          text: 'Form submitted successfully! We will contact you soon.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        // Clear the form
        setFormData({
          contactname: "",
          contactphone: "",
          contactemail: "",
          contactmessage: "",
        });
      } catch (error) {
        console.error("Form submission error:", error);
        // SweetAlert error message
        Swal.fire({
          title: 'Error!',
          text: 'There was an error submitting the form. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <>
    <Helmet>
      <link rel="canonical" href={currentUrl} />
      <title>Contact Us - Ashdip IT Solutions</title>
    </Helmet>
    <div id="contactussection" className='p-8 md:p-10 shadow-sm w-full font-serif'>
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row gap-8">
        <div className="flex-1">
          <h1 className="text-5xl font-bold leading-tight mb-4">Contact Us</h1>
          <h2 className="text-lg leading-7 mb-6">
            We would love to hear from you. Please fill out the form below, and we will get in touch with you shortly.
          </h2>
          <img className="w-full max-w-md h-auto" 
            alt="3D Contact Us Icon"
            src="../images/contactus3dicon.png?height=300&width=300" 
          />
        </div>
        <div className="flex-1 w-full shadow-lg p-5">
        <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="contactname" className="block text-lg font-bold  mb-2">
                Name
                {errors.contactname && <span className="float-right text-sm text-red-600">{errors.contactname}</span>}
              </label>
              <input
                id="contactname"
                name="contactname"
                type="text"
                value={formData.contactname}
                onChange={handleChange}
                className="w-full p-1.5 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="contactphone" className="block text-lg mb-2 font-bold">
                Mobile No
                {errors.contactphone && <span className="float-right text-sm text-red-600">{errors.contactphone}</span>}
              </label>
              <input
                id="contactphone"
                name="contactphone"
                type="text"
                value={formData.contactphone}
                onChange={handleChange}
                className="w-full p-1.5 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="contactemail" className="block text-lg mb-2 font-bold">
                Email
                {errors.contactemail && <span className="float-right text-sm text-red-600">{errors.contactemail}</span>}
              </label>
              <input
                id="contactemail"
                name="contactemail"
                type="email"
                value={formData.contactemail}
                onChange={handleChange}
                className="w-full p-1.5 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="contactmessage" className="block text-lg mb-2 font-bold">
                Message
              </label>
              <textarea
                id="contactmessage"
                name="contactmessage"
                value={formData.contactmessage}
                onChange={handleChange}
                className="w-full  border border-gray-300 rounded-md"
                rows={4}
              />
            </div>
            <div className="flex flex-wrap gap-4">
              <button
                type="submit"
                className="px-8 py-2.5 bg-gray-900 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-800"
              >
                Reach Us
              </button>
              <ScrollLink
                to="contactussection"
                smooth={true}
                duration={50}
                className="px-4 py-2.5 border border-black text-gray-900 font-semibold rounded-lg cursor-pointer flex items-center"
                onClick={handleModalOpen}
              >
                <span>Project Inquiry</span>
                <ArrowUpRightIcon size={24} />
              </ScrollLink>
            </div>
          </form>
        </div>
      </div>
      {/* Modal for Project Inquiry */}
      <InquiryFormModal show={modalShow} handleClose={handleModalClose} />
    </div>
    </>
  );
}
