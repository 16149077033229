import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronDown, Menu, X } from 'lucide-react';
import './Header.css';

const HeaderLink = ({ to, children }) => (
  <Link
    to={to}
    className="group relative inline-block font-semibold text-black"
  >
    <span className="header-3d-text relative z-10 block transition-transform duration-300 group-hover:transform group-hover:translate-y-[-4px]">
      {children}
    </span>
    <span className="absolute bottom-0 left-0 h-[2px] w-full origin-left scale-x-0 bg-black transition-transform duration-300 group-hover:scale-x-100"></span>
  </Link>
);

export default function Header() {
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleLoginClick = () => {
    navigate("/adminlogin");
  };

  return (
    <header className="flex flex-wrap items-center justify-between border-b-2 border-black p-3 w-full" style={{ backgroundColor: '#f1e9e973' }}>
      <div className="flex items-center">
        <img 
          src="/ashdip-logo.png" 
          onClick={handleHomeClick}
          alt="Ashdip logo" 
          className="mr-2 w-32 sm:w-44 cursor-pointer transition-transform duration-300 hover:scale-105"  
        />
      </div>

      <button
        className="lg:hidden"
        onClick={toggleMobileMenu}
        aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
      >
        {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
      </button>

      <nav className={`${isMobileMenuOpen ? 'flex' : 'hidden'} lg:flex flex-col lg:flex-row w-full lg:w-auto items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-8 mt-4 lg:mt-0`}>
        <HeaderLink to="/">Home</HeaderLink>

        <div className="relative">
          <button
            className="inline-flex items-center gap-2 text-black hover:text-gray-700 font-semibold"
            onClick={toggleServices}
            aria-expanded={isServicesOpen}
            aria-haspopup="true"
          >
            <span className="header-3d-text">Services</span>
            <ChevronDown className={`h-4 w-4 transition-transform ${isServicesOpen ? 'rotate-180' : ''}`} />
          </button>
          {isServicesOpen && (
            <div className="lg:absolute top-full left-0 mt-2 w-52 bg-white text-black shadow-lg rounded-lg z-10 p-2">
              <nav className="space-y-2 text-black">
                {[
                  { to: "/services/ux-ui-design", label: "UX UI Design" },
                  { to: "/services/web-design-and-development", label: "Web Design and Development" },
                  { to: "/services/web-app-development", label: "Web App Development" },
                  { to: "/services/mobile-app-development", label: "Mobile App Development" },
                  { to: "/services/digital-marketing", label: "Digital Marketing" },
                  { to: "/services/partner", label: "Partner" },
                ].map((service) => (
                  <Link 
                    key={service.to}
                    to={service.to}
                    className="block hover:bg-gray-100 py-2 px-4 text-black rounded transition duration-150 ease-in-out"
                    onClick={() => {
                      setIsServicesOpen(false);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    {service.label}
                  </Link>
                ))}
              </nav>
            </div>
          )}
        </div>

        <HeaderLink to="/products">Products</HeaderLink>
        <HeaderLink to="/about">About</HeaderLink>
        <HeaderLink to="/career">Career</HeaderLink>
        <HeaderLink to="/contactus">Contact Us</HeaderLink>
        <HeaderLink to="/blog">Blog</HeaderLink>
        <button 
          onClick={handleLoginClick} 
          className="text-black border-2 border-black bg-white rounded-lg px-4 py-2 font-extrabold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none" 
          title="Click to view Our Products"
        >
          Login
        </button>
      </nav>
    </header>
  );
}

