import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Check, ChevronDown, ChevronUp } from 'lucide-react';

const serviceDetails = {
  'ux-ui-design': {
    title: 'UX UI Design',
    description: 'Our UX/UI design service focuses on creating intuitive, user-friendly interfaces that enhance user engagement and experience. We ensure that every element of your application or website is designed to meet user needs while aligning with your brand identity.',
    details: [
      'User Research and Analysis',
      'Wireframing and Prototyping',
      'Visual Design and Branding',
      'Usability Testing',
      'Responsive Design',
      'Accessibility Considerations'
    ],
    icon: '🎨'
  },
  'web-design-and-development': {
    title: 'Web Design and Development',
    description: 'We offer comprehensive web design and development services that include everything from responsive design to full-stack development. Our solutions are tailored to meet your business objectives, ensuring your website is both visually appealing and highly functional.',
    details: [
      'Custom Website Design',
      'Responsive Web Development',
      'E-commerce Solutions',
      'Content Management Systems (CMS)',
      'Web Performance Optimization',
      'Cross-browser Compatibility'
    ],
    icon: '💻'
  },
  'web-app-development': {
    title: 'Web App Development',
    description: 'We specialize in building robust and scalable web applications tailored to your business needs. Our development process ensures seamless user experiences and high performance across all platforms.',
    details: [
      'Full-stack Web Application Development',
      'Progressive Web Apps (PWAs)',
      'Single Page Applications (SPAs)',
      'API Development and Integration',
      'Database Design and Management',
      'Cloud-based Solutions'
    ],
    icon: '🚀'
  },
  'mobile-app-development': {
    title: 'Mobile App Development',
    description: 'Our app development services cover everything from native to cross-platform app development. We build apps that are not only functional and scalable but also provide an excellent user experience on all devices.',
    details: [
      // 'iOS App Development',
      'Android App Development',
      'Cross-platform App Development',
      'App UI/UX Design',
      'App Testing and Quality Assurance',
      'App Store Optimization (ASO)'
    ],
    icon: '📱'
  },
  'digital-marketing': {
    title: 'Digital Marketing',
    description: 'Our digital marketing services encompass SEO, social media marketing, email campaigns, and more. We work to increase your online presence, drive traffic to your site, and convert visitors into customers with targeted, effective marketing strategies.',
    details: [
      'Search Engine Optimization (SEO)',
      'Pay-Per-Click (PPC) Advertising',
      'Social Media Marketing',
      'Content Marketing',
      'Email Marketing Campaigns',
      'Analytics and Reporting'
    ],
    icon: '📈'
  },
  'partner': {
    title: 'Partner',
    description: 'We collaborate with industry-leading partners to bring you the best in technology, innovation, and services. Our partnerships are built on trust, expertise, and a shared commitment to success.',
    details: [
      'Technology Partnerships',
      'Strategic Alliances',
      'Joint Venture Opportunities',
      'Reseller Programs',
      'Collaborative Innovation',
      'Shared Resources and Expertise'
    ],
    icon: '🤝'
  }
};

export default function ServicePage() {
  const navigate = useNavigate();
  const { serviceSlug } = useParams();
  const service = serviceDetails[serviceSlug];
  const [expanded, setExpanded] = useState(false);

  if (!service) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Service Not Found</h1>
          <p className="text-xl text-gray-600 mb-8">We couldn't find the service you're looking for.</p>
          <button
            onClick={() => navigate('/services')}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Back to Services
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <button
          onClick={() => navigate('/services')}
          className="mb-8 flex items-center text-blue-600 hover:text-blue-800 bg-transparent border-none cursor-pointer transition duration-300"
        >
          <ArrowLeft className="mr-2 h-5 w-5" />
          <span className="text-lg font-semibold">Back to Services</span>
        </button>
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="p-8">
            <div className="flex items-center mb-6">
              <span className="text-4xl mr-4" role="img" aria-label={service.title}>
                {service.icon}
              </span>
              <h1 className="text-4xl font-bold text-gray-900">{service.title}</h1>
            </div>
            <p className="text-xl mb-8 text-gray-700 leading-relaxed">{service.description}</p>
            <div className="mb-8">
              <button
                onClick={() => setExpanded(!expanded)}
                className="flex items-center justify-between w-full text-left text-xl font-semibold text-gray-900 bg-gray-100 p-4 rounded-lg hover:bg-gray-200 transition duration-300"
              >
                <span>What We Offer</span>
                {expanded ? <ChevronUp className="h-6 w-6" /> : <ChevronDown className="h-6 w-6" />}
              </button>
              {expanded && (
                <ul className="mt-4 space-y-3 pl-6">
                  {service.details.map((detail, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-gray-700">{detail}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="flex justify-center">
              <button 
                onClick={() => navigate('/contactus')} 
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 transform hover:scale-105"
              >
                Get in Touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}