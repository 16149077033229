import Header from '../components/Header'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Process from '../components/Process'
import Team from '../components/Team'
import Contact from '../components/contactus'
import FAQ from '../components/Faq'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <main>
        <Hero />
        <Services />
        <Process />
        <Team />
        <Contact />
        <FAQ />
      </main>
      <Footer />
    </div>
  )
}