import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { ArrowUpRight } from "lucide-react";
import { Helmet } from 'react-helmet';

export default function FAQ() {
  const currentUrl = window.location.href;
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What types of IT solutions do you provide?",
      answer:
        "We offer a range of IT solutions including software development, system integration, cloud services, cybersecurity, and IT consulting.",
    },
    {
      question: "How do you ensure the quality of your software?",
      answer:
        "We follow a rigorous quality assurance process that includes automated and manual testing, code reviews, and continuous integration.",
    },
    {
      question: "What is your approach to project management?",
      answer:
        "We use agile project management methodologies to ensure flexibility and responsiveness throughout the development process.",
    },
    {
      question: "How do you handle data security and privacy?",
      answer:
        "Data security and privacy are top priorities for us. We implement robust security measures to protect your data.",
    },
    {
      question:
        "Can you provide ongoing maintenance after the project is completed?",
      answer:
        "Yes, we offer ongoing support and maintenance services to ensure the continued performance and reliability of your IT solutions.",
    },
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
    <Helmet>
      <link rel="canonical" href={currentUrl} />
      <title>Ashdip IT Solutions</title>
    </Helmet>
    <section className="p-16 font-serif">
      <div className="w-full">
        {/* Section Title and Intro */}
        <div className="">
          <h1 className="text-4xl font-bold text-gray-900">FAQs</h1>
          <h2 className="text-sm text-gray-600 mt-4">
            Common questions about our IT solutions and services.
          </h2>

          {/* Reach Us Button */}
          <ScrollLink
            to="contactussection"
            smooth={true}
            duration={300}
            className="flex float-start items-baseline px-3 py-2 bg-black text-white rounded-lg hover:bg-gray-700 transition duration-300"
            style={{ cursor: "pointer" }}
          >
            <span className="text-lg font-medium">Reach Us</span>
            <ArrowUpRight className="w-6 ml-2" />
          </ScrollLink>
        </div>
      </div>
        {/* Responsive Layout for FAQ and Image */}
        <div className="w-full flex flex-col lg:flex-row  md:flex-row items-start ">
          {/* Image */}
          <img
            className="object-cover w-1/3 mb-8 lg:mb-0"
            src="../images/faq3dicon.png"
            alt="FAQ Icon"
          />

          {/* FAQ Accordion */}
          <div className="flex-1 w-full space-y-4" style={{ fontFamily: "Archivo" }}>
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="border-b border-gray-300 pb-4"
                onClick={() => toggleAccordion(index)}
                style={{ cursor: "pointer" }}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold mb-2 text-gray-800">
                    {faq.question}
                  </h3>
                  <img
                    className="w-8 h-8"
                    alt={openIndex === index ? "Collapse" : "Expand"}
                    src={
                      openIndex === index
                        ? "/images/icon-expand.svg"
                        : "/images/icon-collapse.svg"
                    }
                  />
                </div>
                {openIndex === index && (
                  <div className="text-gray-700 mt-2 transition-all duration-300">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

    </section>
    </>
  );
}
