import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import { FaProjectDiagram, FaUsers, FaBoxes, FaPaintBrush, FaChartLine, FaTruck, FaUserTie, FaChartBar, FaGlobe, FaUserCircle, FaArrowRight, FaCheck, FaRocket, FaHandshake, FaChartPie, FaTwitter, FaLinkedin, FaFacebook, FaInstagram } from 'react-icons/fa';

const Button = ({ children, primary, className, ...props }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`px-8 py-3 rounded-md font-semibold text-lg transition-all duration-300 ${
      primary
        ? 'bg-gray-900 text-white hover:bg-gray-800'
        : 'bg-white text-gray-900 hover:bg-gray-100 border-2 border-gray-900'
    } ${className}`}
    {...props}
  >
    {children}
  </motion.button>
);

const ModuleCard = ({ title, icon: Icon, features, isOpen, toggleOpen }) => (
  <motion.div
    layout
    className="bg-gray-700 rounded-lg shadow-lg overflow-hidden transition-all duration-300 ease-in-out hover:shadow-2xl"
  >
    <motion.div
      className="p-6 cursor-pointer flex justify-between items-center border-b border-gray-700"
      onClick={toggleOpen}
    >
      <div className="flex items-center">
        <Icon className="text-white text-3xl mr-4" />
        <h3 className="text-xl font-semibold text-white">{title}</h3>
      </div>
      <motion.span
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={{ duration: 0.3 }}
        className="text-white text-lg"
      >
        ▼
      </motion.span>
    </motion.div>
    <motion.div
      animate={{ height: isOpen ? 'auto' : 0 }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden"
    >
      <div className="px-6 py-4 bg-gray-800 ">
        {Object.entries(features).map(([subTitle, items]) => (
          <div key={subTitle} className="mb-4">
            <h4 className="font-semibold text-gray-200 mb-2">{subTitle}</h4>
            <ul className="list-disc list-inside pl-5">
              {items.map((item, index) => (
                <li key={index} className="text-gray-300">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </motion.div>
  </motion.div>
);



const InteriorERPLanding = () => {
  const [openModule, setOpenModule] = useState(null);

  const toggleModule = (index) => {
    setOpenModule(openModule === index ? null : index);
  };

  const modules = [
    {
      title: "Project Management",
      icon: FaProjectDiagram,
      features: {
        "Project Planning": [
          "Project creation",
          "Timeline scheduling",
          "Milestone tracking"
        ],
        "Task Management": [
          "Task assignment",
          "Progress tracking",
          "Task prioritization"
        ],
        "Resource Allocation": [
          "Manpower assignment",
          "Equipment allocation"
        ],
        "Budget Management": [
          "Budget planning",
          "Budget tracking (planned vs. actual)"
        ],
        "Project Status Monitoring": [
          "Project dashboard",
          "Progress reports",
          "Project completion percentage"
        ]
      }
    },
    {
      title: "Customer Relationship Management (CRM)",
      icon: FaUsers,
      features: {
        "Customer Profiles": [
          "Customer information storage",
          "Contact history"
        ],
        "Sales Pipeline": [
          "Lead management",
          "Deal tracking"
        ],
        "Quotations & Invoicing": [
          "Create quotations",
          "Send invoices"
        ],
        "Follow-up Reminders": [
          "Automated follow-ups",
          "Task reminders for customer follow-ups"
        ]
      }
    },
    {
      title: "Inventory Management",
      icon: FaBoxes,
      features: {
        "Stock Management": [
          "Current stock levels",
          "Stock movement tracking"
        ],
        "Material Requirements Planning (MRP)": [
          "Forecasting material needs",
          "Purchase order generation"
        ],
        "Reorder Alerts": [
          "Automated low-stock alerts",
          "Restocking notifications"
        ],
        "Supplier Management": [
          "Supplier database",
          "Supplier performance tracking"
        ]
      }
    },
    {
      title: "Design Management",
      icon: FaPaintBrush,
      features: {
        "Design Templates": [
          "Upload and manage design templates",
          "Design revision history"
        ],
        "Design Approval Workflow": [
          "Submit designs for approval",
          "Approval/revision status"
        ],
        "Mood Board Management": [
          "Create and share mood boards",
          "Manage design inspirations"
        ]
      }
    },
    {
      title: "Finance & Accounting",
      icon: FaChartLine,
      features: {
        "Expense Management": [
          "Track project-specific expenses",
          "Overhead expense tracking"
        ],
        "Invoice Management": [
          "Create and manage invoices",
          "Payment tracking"
        ],
        "Payroll": [
          "Employee payment scheduling",
          "Payroll processing"
        ],
        "Financial Reports": [
          "Profit and loss statements",
          "Budget variance reports"
        ]
      }
    },
    {
      title: "Vendor & Supplier Management",
      icon: FaTruck,
      features: {
        "Vendor Database": [
          "Store vendor information",
          "Vendor performance ratings"
        ],
        "Order Management": [
          "Generate purchase orders",
          "Track vendor shipments"
        ],
        "Contract Management": [
          "Manage contracts with suppliers/vendors",
          "Renewal reminders"
        ]
      }
    },
    {
      title: "Human Resource Management (HRM)",
      icon: FaUserTie,
      features: {
        "Employee Management": [
          "Employee profiles",
          "Role assignment"
        ],
        "Attendance Management": [
          "Time tracking",
          "Leave management"
        ],
        "Performance Management": [
          "Track performance metrics",
          "Performance reviews"
        ],
        "Payroll Management": [
          "Salary disbursement",
          "Salary slip generation"
        ]
      }
    },
    {
      title: "Reporting & Analytics",
      icon: FaChartBar,
      features: {
        "Custom Reports": [
          "Generate project-specific and department-wise reports",
          "Sales and revenue reports"
        ],
        "KPI Dashboard": [
          "Key performance indicator tracking",
          "Progress and milestone tracking"
        ],
        "Inventory & Finance Reports": [
          "Inventory usage and reorder reports",
          "Profit and loss analysis"
        ]
      }
    },
    {
      title: "Client Portal",
      icon: FaGlobe,
      features: {
        "Project Overview": [
          "Clients can view their ongoing projects",
          "Design approval and feedback section"
        ],
        "Invoice & Payment History": [
          "Access to invoices and payment status"
        ],
        "Client Feedback": [
          "Provide feedback on completed designs and projects"
        ]
      }
    },
    {
      title: "Employee Portal",
      icon: FaUserCircle,
      features: {
        "Personal Information": [
          "View and update personal profile",
          "Contact details and emergency contacts"
        ],
        "Attendance & Leave Management": [
          "View attendance records",
          "Submit leave requests and view leave status"
        ],
        "Performance & Goals": [
          "Access performance reviews",
          "View and track individual performance goals"
        ],
        "Payroll & Payslips": [
          "View payroll history",
          "Download payslips"
        ],
        "Announcements & Notices": [
          "Access company announcements",
          "View notices and important updates"
        ]
      }
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gray-900 text-white">
        <nav className="container mx-auto px-6 py-3">
          <h1 className="text-3xl font-bold uppercase text-center">Interior ERP</h1>
        </nav>
      </header>

      <main>
        <section className="relative py-20 px-6 overflow-hidden" style={{
          backgroundImage: "url('https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2000&q=80')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
          <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
          <div className="container mx-auto relative z-10 text-center text-white">
            <h2 className="text-5xl font-bold mb-6">Revolutionize Your Interior Design Business</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">Streamline your operations, boost productivity, and enhance client satisfaction with our comprehensive ERP solution.</p>
            <div className="space-x-4">
                <Button className="text-lg  bg-white text-black hover:bg-gray-100">
                  <a href='/contactus' className='text-black'>Get Started Today <FaArrowRight className="inline-block ml-2" /></a>
                </Button>
                <Button className="bg-transparent border-2 border-white text-white hover:bg-white hover:text-gray-900">
                  Free Demo</Button>
            </div>
          </div>
        </section>

        <section className="py-20 px-6 bg-white modulessection font-serif">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center text-black mb-12">Our Comprehensive Modules</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {modules.map((module, index) => (
                <ModuleCard
                  key={index}
                  {...module}
                  isOpen={openModule === index}
                  toggleOpen={() => toggleModule(index)}
                />
              ))}
            </div>
          </div>
        </section>


        <section className="py-20 px-6 bg-gray-100">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-12">Experience the Difference</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
              {[
                { 
                  title: 'Efficiency', 
                  description: 'Streamline your workflows and reduce time spent on administrative tasks.',
                  icon: FaRocket,
                  features: ['Automated task assignments', 'Integrated project timelines', 'Smart resource allocation']
                },
                { 
                  title: 'Collaboration', 
                  description: 'Enhance team communication and client interactions with integrated tools.',
                  icon: FaHandshake,
                  features: ['Real-time project updates', 'Client feedback integration', 'Team chat and file sharing']
                },
                { 
                  title: 'Insights', 
                  description: 'Make data-driven decisions with comprehensive reporting and analytics.',
                  icon: FaChartPie,
                  features: ['Custom KPI dashboards', 'Predictive analytics', 'Financial forecasting']
                }
              ].map((item, index) => (
                <div key={index} className="bg-white p-8 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <item.icon className="text-4xl text-gray-700 mr-4" />
                    <h3 className="text-2xl font-semibold">{item.title}</h3>
                  </div>
                  <p className="text-gray-600 mb-4">{item.description}</p>
                  <ul className="space-y-2">
                    {item.features.map((feature, i) => (
                      <li key={i} className="flex items-center">
                        <FaCheck className="text-green-500 mr-2" />
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-20 px-6 bg-gray-800 mb-8 mx-4 rounded-lg border-1 border-black">
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold mb-6 text-white">Ready to Transform Your Business?</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto text-white">Join thousands of interior design professionals who have elevated their business with Interior ERP.</p>
              <div className='space-x-6'>
                <Button className="text-lg px-10 py-4 bg-white text-black hover:bg-gray-100">
                  <a href='/contactus' className='text-black'>Get Started Today <FaArrowRight className="inline-block ml-2" /></a>
                </Button>
                <Button primary className="text-lg px-10 py-4 bg-black hover:bg-gray-100">
                  <a href='/contactus' className='text-white'>Get Free Demo <FaArrowRight className="inline-block ml-2" /></a>
                </Button>
              </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-12">
  <div className="container mx-auto px-6">
    <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div className="col-span-2">
        <h3 className="text-2xl font-semibold mb-4">About Interior ERP</h3>
        <p className="text-gray-400 mb-4">Empowering interior design businesses with cutting-edge ERP solutions to streamline operations and boost creativity.</p>
        <Button className="bg-white text-gray-900 hover:bg-gray-100">
          <a href='/'>Learn More</a></Button>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
        <ul className="space-y-2">
          <li><a href="/" className="text-gray-400 hover:text-white transition-colors duration-300">Home</a></li>
          <li>
            <Link 
              to="modulessection" 
              smooth={true} 
              duration={500} 
              className="text-gray-400 hover:text-white transition-colors duration-300 cursor-pointer"
            >
              Features
            </Link>
          </li>                
          <li><a href="/products/interior-erp" className="text-gray-400 hover:text-white transition-colors duration-300">Pricing</a></li>
          <li><a href="/contactus" className="text-gray-400 hover:text-white transition-colors duration-300">Contact</a></li>
        </ul>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
        <p className="text-gray-400 break-words">Email: ashdipitsolutions.in@gmail.com</p>
        <p className="text-gray-400">Phone: +91 9309234245</p>
        <div className="flex space-x-4 mt-4">
          <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="text-gray-400 hover:text-white transition-colors duration-300">
            <FaTwitter />
          </a>
          <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="text-gray-400 hover:text-white transition-colors duration-300">
            <FaLinkedin />
          </a>
          <a href="https://www.facebook.com/people/Ashdip-It-Solutions/61561307273820/?mibextid=qi2Omg&rdid=sg6uDrRwxNk8h0HH&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FGdo7TDLddQnY8tAF%2F%3Fmibextid%3Dqi2Omg" className="text-gray-400 hover:text-white transition-colors duration-300">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/ashdipit/?igsh=MWV3cmZpbGc5cGRwYw%3D%3D#" className="text-gray-400 hover:text-white transition-colors duration-300">
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
    <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-500">
      <p>&copy; 2024 Interior ERP. All rights reserved.</p>
    </div>
  </div>
</footer>

    </div>
  );
};

export default InteriorERPLanding;

