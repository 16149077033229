import styles from "./maps-full-map.module.css";

const MapsFullMap = ({ className = "" }) => {
  const mapSrc =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.797823024403!2d73.80502431537985!3d18.62717938735308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9ace5c2babb%3A0xef9f78d5188837bd!2sAshdip%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1687687388471!5m2!1sen!2sin";

  return (
    <div className={`${styles.mapsFullMap} ${className}`}>
      <div className={styles.mapContainer}>
        <iframe
          title="Ashdip IT Solutions Location"
          src={mapSrc}
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  );
};

export default MapsFullMap;
