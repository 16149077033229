import React, { useState } from 'react';
import {  Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Section = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (

    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h1 className="text-lg font-semibold text-gray-800">{title}</h1>
        <svg
          className={`w-6 h-6 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && <div className="mt-4 text-gray-600">{children}</div>}
    </div>
  );
};

export default function TermsOfService() {
  const currentUrl = window.location.href;
  const sections = [
    {
      title: "Acceptance of Terms",
      content: (
        <p>
          By accessing and using the services provided by Ashdip IT Solutions, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
        </p>
      )
    },
    {
      title: "Description of Service",
      content: (
        <p>
          Ashdip IT Solutions provides IT consulting, software development, and related services. The specific details of the services will be outlined in separate agreements or statements of work.
        </p>
      )
    },
    {
      title: "User Responsibilities",
      content: (
        <>
          <p>As a user of our services, you agree to:</p>
          <ul className="list-disc list-inside mb-2 space-y-1">
            <li>Provide accurate and complete information when interacting with us</li>
            <li>Use our services only for lawful purposes</li>
            <li>Respect the intellectual property rights of Ashdip IT Solutions and third parties</li>
            <li>Maintain the confidentiality of any login credentials provided to you</li>
          </ul>
        </>
      )
    },
    {
      title: "Intellectual Property",
      content: (
        <p>
          All content, trademarks, and intellectual property on our website and in our services are the property of Ashdip IT Solutions or its licensors. You may not use, reproduce, or distribute our intellectual property without our express written permission.
        </p>
      )
    },
    {
      title: "Limitation of Liability",
      content: (
        <p>
          Ashdip IT Solutions shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services or any related matter.
        </p>
      )
    },
    {
      title: "Termination",
      content: (
        <p>
          We reserve the right to terminate or suspend your access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties, or for any other reason.
        </p>
      )
    },
    {
      title: "Governing Law",
      content: (
        <p>
          These Terms of Service shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.
        </p>
      )
    },
    {
      title: "Changes to Terms",
      content: (
        <p>
          We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes. Your continued use of our services after such modifications constitutes your acceptance of the updated terms.
        </p>
      )
    },
    {
      title: "Contact Information",
      content: (
        <p>
          If you have any questions about these Terms of Service, please contact us at:{' '}
          <a href="mailto:info@ashdipitsolutions.in" className="text-blue-600 hover:underline">
            info@ashdipitsolutions.in
          </a>
        </p>
      )
    }
  ];

  return (
    <>
    <Helmet>
      <link rel="canonical" href={currentUrl} />
      <title>Terms of Service - Ashdip IT Solutions</title>
    </Helmet>

    <div className="min-h-screen bg-gray-300 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="bg-gray-700 py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-white text-center">Terms of Service</h1>
        </div>
        <div className="p-4 sm:p-6 lg:p-8">
          <Link to="/" className="inline-block mb-6 text-blue-600 hover:underline">
            &larr; Back to Homepage
          </Link>
          <h2 className="mb-6 text-gray-600 mt-6 text-base">
            These Terms of Service govern your use of Ashdip IT Solutions' website and services. By accessing our site or using our services, you agree to these terms. Please read them carefully.
          </h2>
          
          {sections.map((section, index) => (
            <Section key={index} title={section.title}>
              {section.content}
            </Section>
          ))}

          <p className="text-sm text-gray-500 mt-8 text-center">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
    </>
  );
}