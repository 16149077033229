import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import axiosInstance from "../services/AxiosInstance"; 

const InquiryFormModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    projectDescription: '',
    budget: ''
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset any previous error
    setSuccess(false); // Reset success message
    try {
      await axiosInstance.post("/backend/saveprojectinquiry", formData); 
      console.log("Form submitted:", formData);
      setSuccess(true); // Set success state
      setFormData({ // Reset form after submission
        name: '',
        email: '',
        phone: '',
        companyName: '',
        projectDescription: '',
        budget: ''
      });
      setTimeout(handleClose, 2000); // Close the modal after 2 seconds
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting your inquiry. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '30px', fontWeight: '800' }}>
          Project Inquiry
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Your inquiry has been submitted successfully!</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Enter your name"
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              placeholder="Enter your email"
            />
          </Form.Group>
          <Form.Group controlId="formPhone" className="mt-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Enter your phone number"
            />
          </Form.Group>
          <Form.Group controlId="formCompanyName" className="mt-3">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              required
              placeholder="Enter your company name"
            />
          </Form.Group>
          <Form.Group controlId="formProjectDescription" className="mt-3">
            <Form.Label>Project Description</Form.Label>
            <Form.Control
              as="textarea"
              name="projectDescription"
              value={formData.projectDescription}
              onChange={handleInputChange}
              rows={3}
              required
              placeholder="Describe your project"
            />
          </Form.Group>
          <Form.Group controlId="formBudget" className="mt-3">
            <Form.Label>Budget</Form.Label>
            <Form.Control
              type="text"
              name="budget"
              value={formData.budget}
              onChange={handleInputChange}
              placeholder="Enter your budget"
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="float-end mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InquiryFormModal;
