import React, { useState, useEffect, useRef, useCallback } from 'react'
import { motion } from 'framer-motion'
import { Clock, LogOut, Video, Monitor, AlertTriangle, Activity, Calendar } from 'lucide-react'
import axiosInstance from '../../../services/AxiosInstance'
import BreaksContent from './BreaksContent'

const DashboardContent = ({ employeeId }) => {
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  const [checkInTime, setCheckInTime] = useState(null)
  const [timeWorked, setTimeWorked] = useState(0)
  const [isRecording, setIsRecording] = useState(false)
  const [isAway, setIsAway] = useState(false)
  const [currentBreak, setCurrentBreak] = useState(null)
  const [breakStartTime, setBreakStartTime] = useState(null)
  // eslint-disable-next-line
  const [totalBreakTime, setTotalBreakTime] = useState(0);

  const [employeeBreak, setEmployeeBreak] = useState([]);
  const [todayRecords, setTodayRecords] = useState([]);


  const videoRef = useRef(null)
  const screenRef = useRef(null)
  const timerRef = useRef(null)
  const awayTimerRef = useRef(null)
  const videoRecorderRef = useRef(null)
  const screenRecorderRef = useRef(null)
  const chunkIntervalRef = useRef(null)

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  const startTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current)
    timerRef.current = setInterval(() => {
      setTimeWorked(prev => prev + 1)
    }, 1000)
  }, [])

  const fetchEmployeeBreak = async () => {
    try {
      const response = await axiosInstance.get(`/backend/break/employee/${employeeId}`);
      setEmployeeBreak(response.data)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching employee Breaks:", error);
    }
  }


  // const fetchLoggedInEmployeeData = async () => {
  //   try {
  //     const response = await axiosInstance.get(`/backend/employee/${employeeId}`);
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching employee data:", error);
  //   }
  // };




  useEffect(() => {
    // fetchLoggedInEmployeeData();

    fetchEmployeeBreak();
// eslint-disable-next-line
  }, [employeeId]);


  useEffect(() => {
    if (employeeBreak) {
      const today = new Date().toLocaleDateString();

      // Filter today's break records
      const records = employeeBreak.filter((record) => {
        const recordDate = new Date(record.breakStartTime).toLocaleDateString();
        return recordDate === today;
      });

      setTodayRecords(records);
    }
  }, [employeeBreak]);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }, [])

  const alertAdmin = useCallback(async () => {
    try {
      await axiosInstance.post(`/backend/auth/admin/alert`, { employeeId, reason: 'Employee away for more than 5 minutes' })
    } catch (error) {
      console.error('Failed to send alert to admin:', error)
    }
  }, [employeeId])

  const startAwayTimer = useCallback(() => {
    stopTimer()
    awayTimerRef.current = setTimeout(() => {
      setIsAway(true)
      alertAdmin()
    }, 5 * 60 * 1000) // 5 minutes
  }, [alertAdmin, stopTimer])

  const stopAwayTimer = useCallback(() => {
    if (awayTimerRef.current) {
      clearTimeout(awayTimerRef.current)
      awayTimerRef.current = null
    }
  }, [])

  const compressVideo = async (blob) => {
    return new Promise((resolve) => {
      const video = document.createElement('video')
      video.src = URL.createObjectURL(blob)
      video.onloadedmetadata = () => {
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth / 2
        canvas.height = video.videoHeight / 2
        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        canvas.toBlob((compressedBlob) => {
          resolve(compressedBlob)
        }, 'video/webm', 0.5) // Adjust quality (0.5) as needed
      }
    })
  }

  const uploadChunk = useCallback(async (blob, recordingType, retries = 3) => {
    const compressedBlob = await compressVideo(blob)
    const formData = new FormData()
    formData.append('file', compressedBlob, `${recordingType}_${Date.now()}.webm`)

    try {
      const response = await axiosInstance.post(`/backend/recording/upload/${employeeId}/${recordingType}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      console.log(`${recordingType} chunk uploaded successfully:`, response.data)
      return true
    } catch (error) {
      console.error(`Failed to upload ${recordingType} chunk:`, error)
      if (retries > 0) {
        console.log(`Retrying upload... (${retries} attempts left)`)
        await new Promise(resolve => setTimeout(resolve, 1000)) // Wait 1 second before retrying
        return uploadChunk(blob, recordingType, retries - 1)
      }
      return false
    }
  }, [employeeId])

  const startUploadingVideoChunks = useCallback((stream, recordingType) => {
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: 'video/webm;codecs=vp8,opus',
      videoBitsPerSecond: 1000000
    })

    let chunks = []

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data)
      }
    }

    mediaRecorder.onstop = async () => {
      if (chunks.length > 0) {
        const blob = new Blob(chunks, { type: 'video/webm' })
        console.log(`Processing ${recordingType} chunk of size: ${blob.size} bytes`)

        const uploadSuccess = await uploadChunk(blob, recordingType)

        if (uploadSuccess) {
          console.log(`${recordingType} chunk uploaded successfully`)
        } else {
          console.error(`Failed to upload ${recordingType} chunk after multiple attempts`)
        }

        chunks = [] // Clear the chunks array after processing
      }
    }

    mediaRecorder.start()
    console.log(`Started ${recordingType} recording`)

    // Stop and restart the recorder every 1 hour to trigger the onstop event
    chunkIntervalRef.current = setInterval(() => {
      mediaRecorder.stop()
      mediaRecorder.start()
    }, 3600000) // 1 hour in milliseconds

    return mediaRecorder
  }, [uploadChunk])

  const startRecording = useCallback(async () => {
    try {
      const employeeIdLocal = localStorage.getItem("employeeId")

      await axiosInstance.post(`/backend/recording/start/${employeeIdLocal}`)
      const videoStream = await navigator.mediaDevices.getUserMedia({ video: true })
      const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true })

      const setSrcObjectWithRetry = (ref, stream, maxRetries = 5) => {
        let retries = 0
        const attempt = () => {
          if (ref.current) {
            ref.current.srcObject = stream
          } else if (retries < maxRetries) {
            retries++
            setTimeout(attempt, 200) // Retry after 200ms
          } else {
            console.error(`Failed to set srcObject after ${maxRetries} attempts`)
          }
        }
        attempt()
      }

      setSrcObjectWithRetry(videoRef, videoStream)
      setSrcObjectWithRetry(screenRef, screenStream)

      setIsRecording(true)
      startAwayTimer()

      //const videoRecorder = startUploadingVideoChunks(videoStream, 'video')
      const screenRecorder = startUploadingVideoChunks(screenStream, 'screen')

      //videoRecorderRef.current = videoRecorder
      screenRecorderRef.current = screenRecorder

      // Save recording state to localStorage
      localStorage.setItem('isRecording', 'true')
    } catch (error) {
      console.error('Failed to start recording:', error)
      setIsRecording(false)
    }
  }, [startAwayTimer, startUploadingVideoChunks])

  const stopRecording = useCallback(async () => {
    try {
      await axiosInstance.post(`/backend/recording/stop/${employeeId}`)
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop())
      }
      if (screenRef.current && screenRef.current.srcObject) {
        screenRef.current.srcObject.getTracks().forEach(track => track.stop())
      }
      if (videoRecorderRef.current) {
        videoRecorderRef.current.stop()
      }
      if (screenRecorderRef.current) {
        screenRecorderRef.current.stop()
      }
      if (chunkIntervalRef.current) {
        clearInterval(chunkIntervalRef.current)
      }
      setIsRecording(false)
      stopAwayTimer()

      // Remove recording state from localStorage
      localStorage.removeItem('isRecording')
    } catch (error) {
      console.error('Failed to stop recording:', error)
    }
  }, [employeeId, stopAwayTimer])

  const handleBreakRequest = useCallback(async (breakType, breakTime) => {
    if (!employeeId) {
      console.error('Employee ID not found')
      return
    }
    try {
      const response = await axiosInstance.post(
        // `/backend/employee/${employeeId}/break`,
        `/backend/break/${employeeId}/start`,
        {}, {
        params: {
          breakType: breakType,
          breakTime: breakTime
        },
      });
      console.log(response.data)
      setCurrentBreak(response.data)
      setBreakStartTime(Date.now())
      stopTimer()
    } catch (error) {
      console.error('Break request failed:', error)
    }
  }, [employeeId, stopTimer])

  const handleEndBreak = useCallback(async (breakId) => {
    console.log(breakId)
   
    if (!breakId) {
      console.error('Invalid break state')
      return
    }
    try {
      const response = await axiosInstance.post(`/backend/break/${breakId}/end`)
      console.log(response.data)
      // const breakDuration = (Date.now() - breakStartTime) / 1000 // in seconds
      // const maxBreakTime = currentBreak.type === 'lunch' ? 45 * 60 : 10 * 60 // 45 min for lunch, 10 min for tea
      // const actualBreakTime = Math.min(breakDuration, maxBreakTime)
      // setTotalBreakTime(prev => prev + actualBreakTime)
      // setCurrentBreak(null)
      // setBreakStartTime(null)
      // startTimer()
    } catch (error) {
      console.error('End break failed:', error)
    }
    // eslint-disable-next-line
  }, [employeeId, currentBreak, breakStartTime, startTimer])

  const handleCheckIn = async () => {
    if (!employeeId) return
    try {
      // console.log(employeeId)
      const response = await axiosInstance.post(`/backend/employee/checkin/${employeeId}`)
      setIsCheckedIn(true)
      setCheckInTime(new Date(response.data.checkInTime))
      startTimer()
      startRecording()
    } catch (error) {
      console.error('Check-in failed:', error)
    }
  }

  const handleCheckOut = async () => {
    if (!employeeId) return
    try {
      await axiosInstance.post(`/backend/employee/checkout/${employeeId}`)
      setIsCheckedIn(false)
      setCheckInTime(null)
      setTimeWorked(0)
      stopTimer()
      stopRecording()
      console.log(totalBreakTime);
    } catch (error) {
      console.error('Check-out failed:', error)
    }
  }

  const resetAwayTimer = useCallback(() => {
    stopAwayTimer()
    setIsAway(false)
    if (isCheckedIn) {
      startAwayTimer()
    }
  }, [isCheckedIn, startAwayTimer, stopAwayTimer])

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const statusResponse = await axiosInstance.get(`/backend/employee/status/${employeeId}`)
        if (statusResponse.data) {
          setIsCheckedIn(statusResponse.data.user.checkedIn)
          if (statusResponse.data.user.checkedIn) {
            setCheckInTime(new Date(statusResponse.data.checkInTime))
            startTimer()
            // Check if there was an active recording session
            const wasRecording = localStorage.getItem('isRecording') === 'true'
            if (wasRecording) {
              startRecording()
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch user status:', error)
      }
    }
    if (employeeId) {
      fetchUserStatus()
    }

    return () => {
      stopTimer()
      stopRecording()
    }
  }, [employeeId, startRecording, stopRecording, startTimer, stopTimer])

  useEffect(() => {
    window.addEventListener('mousemove', resetAwayTimer)
    window.addEventListener('keydown', resetAwayTimer)

    return () => {
      window.removeEventListener('mousemove', resetAwayTimer)
      window.removeEventListener('keydown', resetAwayTimer)
    }
  }, [resetAwayTimer])

  const getProgressValue = () => {
    const totalSeconds = 8 * 60 * 60 // 8-hour workday
    return (timeWorked / totalSeconds) * 100
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="grid grid-cols-1 md:grid-cols-2 gap-6"
    >
      <div className="col-span-1 md:col-span-2 bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Time Tracker</h2>
          <Activity className="h-6 w-6 text-gray-400" />
        </div>
        <div className="text-5xl font-bold mb-4">{formatTime(timeWorked)}</div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${getProgressValue()}%` }}></div>
        </div>
        {isCheckedIn && checkInTime && (
          <p className="text-sm text-gray-600 mb-4">
            Checked in at: {checkInTime.toLocaleTimeString()} {checkInTime.getDate()}-{checkInTime.getMonth() + 1}-{checkInTime.getFullYear()}
          </p>
        )}
        {isCheckedIn ? (
          <button
            onClick={handleCheckOut}
            className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-200"
          >
            <LogOut className="inline-block mr-2 h-4 w-4" />
            Check Out
          </button>
        ) : (
          <button
            onClick={handleCheckIn}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-200"
          >
            <Clock className="inline-block mr-2 h-4 w-4" />
            Check In
          </button>
        )}
      </div>

      {isRecording && (
        <div className="col-span-1 md:col-span-2 bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4">Live Recording</h3>
          <p className="text-sm text-gray-600 mb-4">Your video and screen are being recorded</p>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Video className="h-6 w-6 text-gray-400" />
              <video ref={videoRef} autoPlay muted className="w-full h-auto rounded-lg border" />
            </div>
            <div className="space-y-2">
              <Monitor className="h-6 w-6 text-gray-400" />
              <video ref={screenRef} autoPlay muted className="w-full h-auto rounded-lg border" />
            </div>
          </div>
        </div>
      )}

      {isAway && (
        <div className="col-span-1 md:col-span-2 bg-yellow-100 border-yellow-300 border rounded-lg p-4">
          <div className="flex items-center space-x-2">
            <AlertTriangle className="h-6 w-6 text-yellow-600" />
            <p className="text-yellow-800">You have been inactive for more than 5 minutes. Admin has been notified.</p>
          </div>
        </div>
      )}

      <BreaksContent
        currentBreak={currentBreak}
        todayRecords={todayRecords}
        handleBreakRequest={handleBreakRequest}
        handleEndBreak={handleEndBreak}
      />

      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Today's Schedule</h2>
          <Calendar className="h-6 w-6 text-gray-400" />
        </div>
        <ul className="space-y-2">
          <li className="flex justify-between items-center">
            <span>Team Meeting Morning</span>
            <span className="text-sm text-gray-600">10:15 AM</span>
          </li>
          <li className="flex justify-between items-center">
            <span>Team Meeting Evening</span>
            <span className="text-sm text-gray-600">6:30 PM</span>
          </li>
        </ul>
      </div>
    </motion.div>
  )
}

export default DashboardContent

