import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import EmployeeList from './admin/EmployeeList';
import LeaveRequests from './admin/LeaveRequests';
import Notifications from './admin/Notifications';

export default function PIM() {
  const [darkMode, setDarkMode] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [activeTab, setActiveTab] = useState('employees'); // Default active tab

  useEffect(() => {
    // Check and apply dark mode
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    document.body.classList.toggle('dark', isDarkMode);

    // Fetch employees and leave requests on mount
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('/backend/auth/admin/employees');
      const transformedData = response.data.map((employee) =>
        Object.fromEntries(
          Object.entries(employee).map(([key, value]) => [key, value ?? ''])
        )
      );
      setEmployees(transformedData);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };


  return (
    <div className={`min-h-screen p-4 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="max-w-6xl mx-auto">
        {/* Tab Navigation */}
        <div className="flex mb-6">
          <button
            className={`px-4 py-2 font-semibold ${activeTab === 'employees' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'}`}
            onClick={() => setActiveTab('employees')}
          >
            Employees
          </button>
          <button
            className={`px-4 py-2 font-semibold ${activeTab === 'leaves' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'}`}
            onClick={() => setActiveTab('leaves')}
          >
            Leave Requests
          </button>
          <button
            className={`px-4 py-2 font-semibold ${activeTab === 'notifications' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'}`}
            onClick={() => setActiveTab('notifications')}
          >
            Notifications
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === 'employees' && (
          <EmployeeList
            employees={employees}
          />
        )}

        {activeTab === 'leaves' && (
          <LeaveRequests   />
        )}

        {activeTab === 'notifications' && (
          <Notifications   />
        )}
      </div>
    </div>
  );
}
