'use client'

// import { useState } from 'react'
// import { Button } from 'react-bootstrap'
// import ProjectInquiryModal from './Projectinquirymodel'
import { Helmet } from 'react-helmet';
export default function Team() {
  const currentUrl = window.location.href;
  // const [isModalOpen, setIsModalOpen] = useState(false)

  // const handleOpenModal = () => {
  //   setIsModalOpen(true)
  // }

  // const handleCloseModal = () => {
  //   setIsModalOpen(false)
  // }

  return (
    <>
    <Helmet>
      <link rel="canonical" href={currentUrl} />
      <title>Our Team - Ashdip IT Solutions</title>
    </Helmet>
    <section className="py-6 px-4 shadow-sm" id="team-section">
      <div className="flex flex-col  justify-between items-center">
        <div className="w-full ">
          <div className="relative ">
            <img
              src="../images/teamsection-img1.png"
              alt="Team member 1"
              className=" object-cover"
            />
          </div>
        </div>
        <div className="w-full ">
          <div className="relative ">
            <img
              src="../images/teamsection-img2.png"
              alt="Laptop with project inquiry"
              className=" object-cover"
            />
            {/* <div className="absolute inset-0 flex items-center justify-center">
              <div className="absolute  flex items-center justify-center p-3" style={{transform:'rotate(-14deg)',top:'5.5rem',left:'94px'}}>
                <Button 
                  onClick={handleOpenModal}
                  className=" bg-black text-white hover:bg-black/90 border-black text-xs sm:text-sm"
                >
                  Project Inquiry
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>   

      {/* <ProjectInquiryModal isOpen={isModalOpen} onClose={handleCloseModal} />   */}
    </section>
    </>
  )
}