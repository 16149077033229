

import {BrowserRouter , Route, Routes ,useLocation} from 'react-router-dom';

import Home from './pages/Home'
import ContactUsPage from './pages/ContactUsPage';
import ServicesPage from './pages/ServicesPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import CookiesSettings from './components/CookiesSettings';
import ServicePageDetails from './pages/ServicePageDetails';
import About from './pages/AboutPage';
import Products from './pages/OurProductsPage';
import TransportationERP from './pages/TransportationERPLandingPage';
import InteriorERP from './pages/InteriorERPLandingPage';

import AdminLoginPage from "./pages/Loginpage"
import RegisterPage from './components/admin/RegisterPage';
import PIM from './components/admin/employee/PIM';

import './App.css';
import DetailNavbar from './components/detailNavbar/DetailNavbar';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/admin/sidebar/Sidebar';
import CareerPage from './pages/CareerPage';
import ApplyNowPage from './pages/ApplyNowPage';
import StartTestpage from './pages/StartTestpage';
import McqTestPage from './pages/McqTestPage';
import McqTestQuestion from './components/mcqTestQuestion/McqTestQuestion';
import CodingTestInstructionPage from './pages/CodingTestInstructionPage';
import CodingQuestionPage from './pages/CodingQuestionPage';
import UploadDocumentPage from './pages/UploadDocumentPage';
import AdminDashboard from "./pages/AdminDashboard";
import DetailsTable from './components/admin/detailsTable/DetailsTable';
import AddMcqPage from './pages/AddMcqPage';
import AddCodingPage from './pages/AddCodingPage';
import ShowMcqPage from './pages/ShowMcqPage';
import ShowCodingQuestionPage from './pages/ShowCodingQuestionPage';
import UpdateMcqPage from './pages/UpdateMcqPage';
import UpdateCodingQuestionPage from './pages/UpdateCodingQuestionPage';
import ContactInquiriesPage from './pages/ContactInquiriesPage';
import ProjectInquiriesPage from './pages/ProjectInquiriesPage';
import { DarkModeProvider } from './components/DarkModeContext';

import EmployeeDashboard from './components/admin/employee/dashboard';

import ScrollToTop from './components/ScrollToTop';

const Layout = ({ children }) => {
    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/admin');

    return (
        <>
            {!isAdminRoute && <DetailNavbar />}
            {!isAdminRoute && <Navbar />}
            <main  >{children}</main>
        </>
    );
};


	const App = () => {

    return (

        <BrowserRouter >
        <ScrollToTop />
         <DarkModeProvider>
            <Routes>
                {/* public routes */}
				<Route path="/adminlogin" element={<AdminLoginPage />} />
                
				 {/* <Route path="/" element={<Desktop1280x />} /> */}				
				
                <Route path="/" element={<Home />} /> 
                <Route path="/home" element={<Home />} /> 
                <Route path="/contactus" element={<ContactUsPage />} /> 
                <Route path="/services" element={<ServicesPage />} /> 
                <Route path="/about" element={<About />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/transportation-erp" element={<TransportationERP />} />
                <Route path="/products/interior-erp" element={<InteriorERP />} />

                <Route path="/services/:serviceSlug" element={<ServicePageDetails />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/cookies-settings" element={<CookiesSettings />} />

                <Route path='/career' element={<Layout><CareerPage /></Layout>} />
                {/* <Route path='/home' element={<Layout><Home /></Layout>} /> */}

                {/* protected routes */}
				<Route path="/admindashboard" element={<Layout><AdminDashboard /></Layout>} />						
				
                <Route path="/apply/:title" element={<Layout><ApplyNowPage /></Layout>} />
                <Route path='/start-test' element={<Layout><StartTestpage /></Layout>} />
                <Route path='/Instructions' element={<Layout><McqTestPage /></Layout>} />
                <Route path='/mcq-test' element={<Layout><McqTestQuestion /></Layout>} />
                <Route path='/coding-instruction' element={<Layout><CodingTestInstructionPage /></Layout>} />
                <Route path='/coding-test' element={<Layout><CodingQuestionPage /></Layout>} />
                <Route path='/upload-document' element={<Layout><UploadDocumentPage /></Layout>} />
                
                {/* Admin routes with Sidebar */}
                <Route path='/admin/*' element={<Sidebar />}>
                    {/* <Route path='/admin/dashboard/*'  /> */}
                    <Route path='dashboard' element={<AdminDashboard />} />
                    <Route path='dashboard/:category' element={<DetailsTable />} />
                    <Route path='add-mcq' element={<AddMcqPage />} />
                    <Route path='add-coding' element={<AddCodingPage />} />
                    <Route path='show-mcq' element={<ShowMcqPage />} />
                    <Route path='show-coding-question' element={<ShowCodingQuestionPage />} />
                    <Route path='contact-inquiries' element={<ContactInquiriesPage />} />
                    <Route path='project-inquiries' element={<ProjectInquiriesPage />} />
                    <Route path='update-mcq' element={<UpdateMcqPage />} />
                    <Route path='update-coding-question' element={<UpdateCodingQuestionPage />} />
                    <Route path='upload-documents' element={<UploadDocumentPage />} />
                    <Route path="register" element={<RegisterPage />} /> 
                    <Route path='employeeportal' element={<PIM />} />

                    {/* <Route path='users' element={<AdminDashboard />} /> */}
                </Route>

                <Route path='/employee/dashboard' element={<EmployeeDashboard />} />

            </Routes>
            </DarkModeProvider>
        </BrowserRouter >
    );
}

export default App;
