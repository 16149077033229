import React, { useState } from 'react';
import {  Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Section = ({ title, children }) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h1 className="text-lg font-semibold text-gray-800">{title}</h1>
        <svg
          className={`w-6 h-6 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && <div className="mt-4 text-gray-600">{children}</div>}
    </div>
  );
};

export default function PrivacyPolicy() {
  const currentUrl = window.location.href;
  const sections = [
    {
      title: "Information We Collect",
      content: (
        <>
          <p className="mb-2">We may collect the following types of information:</p>
          <ul className="list-disc list-inside mb-2 space-y-1">
            <li>Personal information (such as name, email address, phone number)</li>
            <li>Usage data (such as IP address, browser type, pages visited)</li>
            <li>Cookies and similar tracking technologies</li>
          </ul>
        </>
      )
    },
    {
      title: "How We Use Your Information",
      content: (
        <>
          <p className="mb-2">We use the collected information for various purposes, including:</p>
          <ul className="list-disc list-inside mb-2 space-y-1">
            <li>Providing and maintaining our services</li>
            <li>Improving and personalizing user experience</li>
            <li>Communicating with you about our services</li>
            <li>Complying with legal obligations</li>
          </ul>
        </>
      )
    },
    {
      title: "Data Protection",
      content: (
        <p className="mb-2">
          We implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage.
        </p>
      )
    },
    {
      title: "Your Rights",
      content: (
        <>
          <h2 className="mb-2">You have the right to:</h2>
          <ul className="list-disc list-inside mb-2 space-y-1">
            <li>Access your personal data</li>
            <li>Correct inaccurate personal data</li>
            <li>Request erasure of your personal data</li>
            <li>Object to processing of your personal data</li>
            <li>Request restriction of processing your personal data</li>
            <li>Request transfer of your personal data</li>
            <li>Withdraw consent</li>
          </ul>
        </>
      )
    },
    {
      title: "Changes to This Privacy Policy",
      content: (
        <p className="mb-2">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last updated" date.
        </p>
      )
    },
    {
      title: "Contact Us",
      content: (
        <>
          <p className="mb-2">
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <p className="font-semibold">
            Email: <a href="mailto:info@ashdipitsolutions.in" className="text-blue-600 hover:underline">info@ashdipitsolutions.in</a>
          </p>
        </>
      )
    }
  ];

  return (
    <>
    <Helmet>
      <link rel="canonical" href={currentUrl} />
      <title>Our Privacy Policy - Ashdip IT Solutions</title>
    </Helmet>
    <div className="min-h-screen bg-gray-300 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="bg-gray-700 py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-white text-center">Privacy Policy</h1>
        </div>
        <div className="p-4 sm:p-6 lg:p-8">
          <Link to="/" className="inline-block  text-blue-600 hover:underline no-underline">
            &larr; Back to Homepage
          </Link>
          <h2 className="mb-6 text-gray-600 mt-6 text-base">
            Welcome to Ashdip IT Solutions' Privacy Policy. Your privacy is critically important to us.
            This policy outlines how we collect, use, and protect your information when you use our services or visit our website.
          </h2>
          
          {sections.map((section, index) => (
            <Section key={index} title={section.title}>
              {section.content}
            </Section>
          ))}

          <p className="text-sm text-gray-500 mt-8 text-center">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
    </>
  );
}