import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Truck, Home, Stethoscope, GraduationCap, ArrowRight } from 'lucide-react';

const products = [
  {
    title: "Transportation ERP",
    description: "Revolutionize your transportation business with our cutting-edge ERP solution.",
    icon: Truck,
    backgroundImage: "./images/transportcardbg.png",
    link: "/products/transportation-erp",
    text: "Navigate the future of logistics with AI-driven route optimization and real-time fleet analytics.",
    modules: [
      "Fleet Management",
      "Employee Management",
      "Student Management",
      "Scheduling & Dispatch",
      "Parent & Student Portal",
      "Finance & Billing",
      "Compliance & Safety",
      "Communication & Reporting",
      "Maintenance & Support",
    ],
  },
  {
    title: "Interior ERP",
    description: "Transform your interiors business with our specialized ERP system.",
    icon: Home,
    backgroundImage: "./images/interiorerpcardbg.png",
    link: "/products/interior-erp",
    text: "Visualize dreams with 3D modeling and streamline projects from concept to completion.",
    modules: [
      "Project Management",
      "Customer Relationship Management (CRM)",
      "Inventory Management",
      "Design Management",
      "Finance & Accounting",
      "Vendor & Supplier Management",
      "Human Resource Management (HRM)",
      "Reporting & Analytics",
      "Client Portal",
      "Employee Portal",
    ],
  },
  {
    title: "Doctors ERP",
    description: "Elevate healthcare management with our tailored ERP for medical professionals.",
    icon: Stethoscope,
    backgroundImage: "./images/doctorerpcardbg.png",
    link: "/products/doctor-erp",
    text: "Empower patient care with AI-assisted diagnostics and seamless telemedicine integration.",
    modules: [
      "Patient Management",
      "Appointment Management",
      "Medical Records Management",
      "Billing and Payments",
      "Staff Management",
      "Inventory Management",
      "Reporting and Analytics",
      "Communication",
      "Administration",
      "Compliance and Security",
    ],
  },
  {
    title: "Online College ERP",
    description: "Empower your educational institution with our comprehensive online college ERP.",
    icon: GraduationCap,
    backgroundImage: "./images/collegeerpcardbg.png",
    link: "/products/onlinecollege-erp",
    text: "Redefine learning with adaptive AI curriculums and immersive virtual classrooms.",
    modules: [
      "Student Management",
      "Learning Management System (LMS)",
      "Faculty Management",
      "Fees and Financial Management",
      "Examination and Certifications",
      "Communication Module",
      "Administrative Management",
      "Library Management",
      "Hostel and Accommodation Management",
      "Parent Portal",
    ],
  },
];

const ProductCard = ({ title, description, icon: Icon, index, backgroundImage, link,text, modules }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    className="relative bg-white rounded-2xl shadow-xl overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>
    <div className="p-8 relative z-10">
      <motion.div
        whileHover={{ rotate: 360 }}
        transition={{ duration: 0.5 }}
        className="w-20 h-20 bg-black rounded-full flex items-center justify-center mb-6 mx-auto"
      >
        <Icon className="w-10 h-10 text-white" />
      </motion.div>
      <h3 className="text-2xl font-extrabold mb-3 text-center text-white p-2 shadow-xl tracking-wide uppercase bg-slate-500">
        {title}
      </h3>
      
      <div className="bg-black bg-opacity-25 rounded-lg p-4 mb-6">
      <p className="text-white text-sm italic mb-4 text-justify">{description} {text}</p>
      <h4 className="text-lg font-semibold text-white mb-2 tracking-wide shadow-md">
        <span className="text-blue-400 uppercase">Seamless Integration</span> with <span className="text-green-300 font-bold">Key Tools</span> :
      </h4>

        <motion.ul
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="text-white text-sm mt-4 space-y-2"
            >
              {modules.map((module, idx) => (
                <motion.li
                  key={idx}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: idx * 0.05 }}
                  className="flex items-center"
                >
                  <div className="w-2 h-2 bg-gray-400 rounded-full mr-2"></div>
                  {module}
                </motion.li>
              ))}
            </motion.ul>
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="w-full bg-blue-800 text-white font-bold py-3 px-6 rounded-full hover:bg-green-600 transition duration-300 flex items-center justify-center group"
      >
        <Link to={link} className="flex items-center justify-center w-full text-white">
          Learn More
          <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
        </Link>
      </motion.button>
    </div>
  </motion.div>
  );

  export default function OurProducts() {
    return(

    <div className="bg-white min-h-screen py-20 px-4 sm:px-6 lg:px-8 font-serif">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-6xl font-extrabold text-center mb-4 text-gray-900">
            Our Products
          </h1>
          <p className="text-xl text-center text-gray-600 mb-6 max-w-4xl mx-auto">
          Discover Ashdip's innovative ERP solutions tailored for industries like transportation, interior design, healthcare, and education. Streamline operations, boost productivity, and drive growth with our cutting-edge, data-driven tools designed to keep your business ahead in a competitive landscape.          </p>
          <p className="text-4xl font-extrabold text-center text-blue-700 mb-16 max-w-4xl mx-auto italic shadow-lg">
            <span className="shadow-md text-shadow-lg">
              Empowering Excellence: <span className="text-blue-900">Your Vision,</span> <span className="text-blue-500">Our Innovation</span>
            </span>
          </p>


        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {products.map((product, index) => (
          <ProductCard key={index} {...product} index={index} />
        ))}
        </div>
        
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="mt-32 text-center bg-green-900 text-white py-16 px-4 rounded-3xl shadow-2xl overflow-hidden relative"
        >
          <div className="absolute top-0 right-0 w-64 h-64 bg-blue-800 rounded-full -mr-32 -mt-32 opacity-50"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-800 rounded-full -ml-32 -mb-32 opacity-50"></div>
          <motion.h2 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2 }}
            className="text-5xl font-bold mb-6 relative z-10"
          >
            Ready to Transform Your Business?
          </motion.h2>
          <motion.p 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.4 }}
            className="text-xl mb-8 max-w-2xl mx-auto relative z-10"
          >
            Let's collaborate to bring your vision to life and drive your business forward with innovative IT solutions. Our team of experts is ready to tailor our ERP products to your specific needs, ensuring a seamless integration that propels your organization to new heights of efficiency and success.
          </motion.p>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.6 }}
          >
            <Link
              to="/contactus"
              className="inline-block bg-white text-blue-900 font-bold py-4 px-8 rounded-full text-xl transition duration-300 hover:bg-blue-100 hover:shadow-lg relative z-10"
            >
              Get Started Today
            </Link>
          </motion.div>
        </motion.section>
      </div>
    </div>
  )
}



