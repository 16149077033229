import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import {  User } from 'lucide-react';

const ToggleSwitch = ({ isOn, onToggle, leftLabel, rightLabel }) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      <span className={`text-sm font-medium ${isOn ? 'text-gray-500' : 'text-gray-900 dark:text-white'}`}>
        {leftLabel}
      </span>
      <button
        onClick={onToggle}
        className="focus:outline-none  focus:ring-sky-600 focus:ring-offset-2"
      >
        <svg width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44" height="24" rx="12" fill={isOn ? "rgb(4 69 103)" : "#ccc"} />
          <circle cx={isOn ? "32" : "12"} cy="12" r="10" fill="white" />
        </svg>
      </button>
      <span className={`text-sm font-medium ${isOn ? 'text-gray-900 dark:text-white' : 'text-gray-500'}`}>
        {rightLabel}
      </span>
    </div>
  );
};

export default function Component() {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    profileImage: null,
  });
  const [createLoginDetails, setCreateLoginDetails] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [darkMode, setDarkMode] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true' ||
      (!('darkMode' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
    setDarkMode(isDarkMode);
    document.body.classList.toggle('dark', isDarkMode);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) { // 1MB
        setError('Image size should not exceed 1MB');
        return;
      }
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        setError('Please upload a JPG, PNG, or GIF file');
        return;
      }
      setFormData(prev => ({ ...prev, profileImage: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    formDataToSend.append('role', isAdmin ? 'admin' : 'employee');
    formDataToSend.append('status', isEnabled ? 'enabled' : 'disabled');

    try {
      const response = await axiosInstance.post('/backend/auth/register', formDataToSend);
      console.log(response);
      if (response.data.success) {
        setSuccess('Registration successful!');
        setFormData({
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          username: '',
          password: '',
          confirmPassword: '',
          profileImage: null,
        });
        setImagePreview(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Registration failed. Please try again.');
    } finally {
      setLoading(false);
      setSuccess('Registration successful!');
      setFormData({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        profileImage: null,
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-4">
      <div className="max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h1 className="text-2xl font-semibold text-center text-gray-900 dark:text-white mb-4">Add New User</h1>
          <ToggleSwitch
            isOn={isAdmin}
            onToggle={() => setIsAdmin(!isAdmin)}
            leftLabel="Employee"
            rightLabel="Admin"
          />
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {(error || success) && (
            <div className={`p-4 rounded ${error ? 'bg-red-50 text-red-700 dark:bg-red-900 dark:text-red-200' : 'bg-green-50 text-green-700 dark:bg-green-900 dark:text-green-200'}`}>
              {error || success}
            </div>
          )}

          <div className="flex items-center justify-center">
            <div className="relative">
              <div className="w-32 h-32 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center overflow-hidden">
                {imagePreview ? (
                  <img src={imagePreview} alt="Profile preview" className="w-full h-full object-cover" />
                ) : (
                  <User className="w-16 h-16 text-gray-400 dark:text-gray-600" />
                )}
              </div>
              <button
                type="button"
                onClick={() => document.getElementById('profileImage').click()}
                className=" absolute bottom-0 right-0 bg-sky-800 text-white rounded-full p-2 shadow-lg hover:bg-sky-600"
              >
                <svg className="w-10 h-10" fill="gray" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </button>
              <input
                id="profileImage"
                type="file"
                accept="image/jpeg,image/png,image/gif"
                onChange={handleImageUpload}
                className="hidden"
              />
            </div>
          </div>
          <p className="text-sm text-center text-gray-500 dark:text-gray-400">
            Upload JPG, PNG, GIF (max. 1MB, 200x200px recommended)
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">First Name*</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Middle Name</label>
              <input
                type="text"
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Last Name*</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
              />
            </div>
          </div>

          {/* <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Employee ID*</label>
            <input
              type="text"
              name="employeeId"
              value={formData.employeeId}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
            />
          </div> */}

          <div className=" pt-6">
            <div className="flex items-center mb-4">
              <span className="text-lg font-medium text-gray-900 dark:text-white">Create Login Details</span>
              <ToggleSwitch
                isOn={createLoginDetails}
                onToggle={() => setCreateLoginDetails(!createLoginDetails)}
                
              />
            </div>

            {createLoginDetails && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Username*</label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Password*</label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 dark:bg-gray-700 dark:text-white"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Confirm Password*</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600 dark:bg-gray-700 dark:text-white"
                    />
                  </div>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Use a strong password with upper and lowercase letters, numbers, and symbols.
                </p>

                <div className="flex items-center gap-3 mt-4">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Account Status</span>
                  <ToggleSwitch
                    isOn={isEnabled}
                    onToggle={() => setIsEnabled(!isEnabled)}
                    leftLabel="Disabled"
                    rightLabel="Enabled"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 bg-red-800 text-white border border-gray-300 rounded-md  hover:bg-red-500  focus:outline-none focus:ring-2  transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-green-800 text-white rounded-md hover:bg-sky-600 focus:outline-none focus:ring-2  disabled:opacity-50 transition-colors"
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}