import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faXTwitter, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faLocationDot, faPhone, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import MapsFullMap from "./MapsFullMap"
import ScrollToTop from './ScrollToTop';

export default function Footer() {
  const whatsappNumber = "9309234245"
  const whatsappMessage = "Hello, I would like to know more about your services."

  return (
    <footer className="bg-gradient-to-br from-gray-800 to-gray-800 text-white relative">
      <ScrollToTop />
      <div className="max-w-7xl mx-auto px-6 sm:px-6 lg:px-8 pt-12 pb-6 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {/* Logo and Company Info */}
          <div className="space-y-6">
            <img src="./ashdip-logo.png" alt="Ashdip IT Solutions Logo" className="w-48 filter brightness-0 invert" />
            <p className="text-gray-300 text-sm leading-relaxed pt-4 pb-4">
              <i>Empowering businesses with innovative IT solutions. We transform ideas into reality, driving growth and efficiency through technology.
              </i>
            </p>
            <div className="flex space-x-4">
              {[
                { icon: faFacebookF, href: "https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg", color: "hover:text-blue-400" },
                { icon: faInstagram, href: "https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==", color: "hover:text-pink-400" },
                { icon: faXTwitter, href: "https://x.com/Ashdipit", color: "hover:text-blue-300" },
                { icon: faLinkedinIn, href: "https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app", color: "hover:text-blue-200" },
                { icon: faYoutube, href: "https://www.youtube.com", color: "hover:text-red-400" },
                { icon: faWhatsapp, href: `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`, color: "hover:text-green-400" },
              ].map((social, index) => (
                <a key={index} href={social.href} className={`text-gray-100 ${social.color} transition-all duration-300`} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={social.icon} size="lg" className="transform hover:scale-125 hover:-translate-y-1" />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h2 className="text-xl font-semibold mb-4 text-white text-center border-b-2 border-gray-600">Quick Links</h2>
            <nav>
              <ul className="space-y-3">
                {['Home', 'About', 'Services','Products', 'Career', 'Contact Us', 'Blog'].map((item) => (
                  <li key={item} className="transition-transform duration-300 hover:translate-x-2">
                    <Link to={`/${item.toLowerCase().replace(/\s+/g, '-')}`} className="text-gray-400 hover:text-white flex items-center">
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2 text-xs" />
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-xl font-semibold mb-4 text-white text-center border-b-2 border-gray-600">Contact Us</h2>
            <ul className="space-y-3 text-gray-300">
              <li className="flex items-start group">
                <FontAwesomeIcon icon={faLocationDot} className="mr-3 mt-1 text-blue-400 group-hover:text-blue-300 transition-colors duration-300" />
                <p className="text-sm leading-relaxed group-hover:text-white transition-colors duration-300">
                  P. J. Chambers, 304/2, Nehru Nagar Rd, Atma Nagar, Kharalwadi, Pimpri Colony, Pune, Pimpri-Chinchwad, Maharashtra 411018
                </p>
              </li>
              <li className="flex items-center group">
                <FontAwesomeIcon icon={faPhone} className="mr-3 text-blue-400 group-hover:text-blue-300 transition-colors duration-300" />
                <a href="tel:9309234245" className="hover:text-white transition-colors duration-300">9309234245</a>
              </li>
              <li className="flex items-center group">
                <FontAwesomeIcon icon={faEnvelope} className="mr-3 text-blue-400 group-hover:text-blue-300 transition-colors duration-300" />
                <a href="mailto:info@ashdipitsolutions.in" className="hover:text-white transition-colors duration-300">info@ashdipitsolutions.in</a>
              </li>
            </ul>
          </div>

          {/* Locate Us */}
          <div>
            <h2 className="text-xl font-semibold mb-4 text-white text-center border-b-2 border-gray-600">Locate Us</h2>
            <div className="rounded-lg overflow-hidden shadow-lg hover:shadow-blue-500/30 transition-all duration-300">
              <MapsFullMap />
            </div>
          </div>
        </div>

       
        {/* Copyright and Additional Links */}
        <div className="border-t border-gray-600 mt-2 pt-2 ">
          <div className="flex flex-col md:flex-row justify-between items-center text-gray-400 text-sm">
            <p className=" ">&copy; 2024 Ashdip IT Solutions. All rights reserved.</p>
            <div className="flex flex-wrap justify-center gap-4">
              {['Privacy Policy', 'Terms of Service', 'Cookies Settings'].map((item) => (
                <Link 
                  key={item} 
                  to={`/${item.toLowerCase().replace(/\s+/g, '-')}`} 
                  className="hover:text-white transition-colors duration-300 relative after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-blue-400 hover:after:w-full after:transition-all after:duration-300"
                >
                  {item}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

