import React, { useState } from 'react';
import {  Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CookieToggle = ({ label, description, isChecked, onChange }) => {
  return (

    <div className="flex items-center justify-between py-4 border-b border-gray-200">
      <div className="flex-grow">
        <h3 className="text-lg font-semibold text-gray-800">{label}</h3>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
      <label className="relative inline-flex items-center cursor-pointer ml-4">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={isChecked}
          onChange={onChange}
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
      </label>
    </div>
  );
};

const saveCookiePreferences = (preferences) => {
  localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
};

const loadCookiePreferences = () => {
  const savedPreferences = localStorage.getItem('cookiePreferences');
  return savedPreferences ? JSON.parse(savedPreferences) : null;
};

export default function CookiesSettings() {
  const currentUrl = window.location.href;
  const [cookiePreferences, setCookiePreferences] = useState(() => {
    return loadCookiePreferences() || {
      necessary: true,
      functional: true,
      analytics: false,
      advertising: false,
    };
  });

  const handleToggle = (cookieType) => {
    setCookiePreferences(prev => {
      const newPreferences = {
        ...prev,
        [cookieType]: !prev[cookieType]
      };
      saveCookiePreferences(newPreferences);
      return newPreferences;
    });
  };

  const handleSave = () => {
    saveCookiePreferences(cookiePreferences);
    alert('Your cookie preferences have been saved!');
  };

  return (
    <>
    <Helmet>
      <link rel="canonical" href={currentUrl} />
      <title>Cookies Settings- Ashdip IT Solutions</title>
    </Helmet>
    <div className="min-h-screen bg-gray-300 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="bg-gray-700 py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-white text-center">Cookies Settings</h1>
        </div>
        <div className="p-4 sm:p-6 lg:p-8">
          <Link to="/" className="inline-block mb-6 text-blue-600 hover:underline">
            &larr; Back to Homepage
          </Link>
          <h2 className="text-2xl font-semibold mb-4 mt-4">Customize Cookie Preferences</h2>
          <p className="mb-6 text-gray-600">
            We use cookies to enhance your browsing experience and provide personalized services. You can customize your cookie preferences here. Changes will take effect immediately.
          </p>
          
          <div className="space-y-4">
            <CookieToggle
              label="Necessary Cookies"
              description="These cookies are essential for the website to function properly."
              isChecked={cookiePreferences.necessary}
              onChange={() => {}} // Necessary cookies can't be disabled
            />
            <CookieToggle
              label="Functional Cookies"
              description="These cookies enable personalized features and remember your preferences."
              isChecked={cookiePreferences.functional}
              onChange={() => handleToggle('functional')}
            />
            <CookieToggle
              label="Analytics Cookies"
              description="These cookies help us understand how visitors interact with our website."
              isChecked={cookiePreferences.analytics}
              onChange={() => handleToggle('analytics')}
            />
            <CookieToggle
              label="Advertising Cookies"
              description="These cookies are used to deliver relevant ads and marketing campaigns."
              isChecked={cookiePreferences.advertising}
              onChange={() => handleToggle('advertising')}
            />
          </div>

          <div className="mt-8 flex justify-end">
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Save Preferences
            </button>
          </div>

          <p className="text-sm text-gray-500 mt-8">
            If you have questions about our cookie policies, please email us at{' '}
            <a href="mailto:info@ashdipitsolutions.in" className="text-blue-600 hover:underline">
              info@ashdipitsolutions.in
            </a>
          </p>

          <p className="text-sm text-gray-500 mt-4 text-center">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
    </>
  );
}